import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../userAuth';
import './user.css';

function UserSignupLogin() {
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { login } = useAuth(); // Assume your useAuth hook has a login function
  const isLocal = window.location.hostname === 'localhost';
  const baseUrl = isLocal ? 'http://localhost:3003' : 'https://crg-1-pakistan-bf9fced80b73.herokuapp.com';

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setError('');
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setError('');
  };

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
    setError('');
  };

  const handleSignup = async (e) => {
    e.preventDefault();

    try {
      const userResponse = await axios.get(`${baseUrl}/users/${email}`);

      if (userResponse.data) {
        const user = userResponse.data;

        localStorage.setItem('title', user.title);
        localStorage.setItem('userId', user._id);
        login(); // Set the authentication status
        navigate('/', { state: { title: user.title } });
      } else {
        const newUser = {
          email,
          phoneNumber: parseInt(phoneNumber),
          password,
        };

        const createUserResponse = await axios.put(`${baseUrl}/users`, newUser);
        const createdUser = createUserResponse.data;
        localStorage.setItem('title', createdUser.title);
        localStorage.setItem('userId', createdUser._id);
        login(); // Set the authentication status

        if (createdUser.title === 'Warehouse') {
          navigate('/warehouse-schedule');
        } else {
          navigate('/', { state: { title: createdUser.title } });
        }
      }

      // Reset form fields
      setEmail('');
      setPhoneNumber('');
      setPassword('');
    } catch (error) {
      console.error(error);
      if (error.response && error.response.status === 404) {
        setError('User not found. Contact us to gain access.');
      } else if (error.response && error.response.status === 400) {
        setError('Incorrect password. Please try again.');
      } else {
        setError('Error signing up/logging in.');
      }
    }
  };

  useEffect(() => {
    const storedTitle = localStorage.getItem('title');
    if (storedTitle) {
      login(); // Set the authentication status
      navigate('/', { state: { title: storedTitle } });
    }
    // eslint-disable-next-line
  }, [login, navigate]);

  return (
    <div className='signupmain'>
      <h2>Sign Up or Log In</h2>
      {error && <div className="error-message">{error}</div>}
      <form>
        <label>Unique Name:</label>
        <input type="email" value={email} onChange={handleEmailChange} />
        <label>Password:</label>
        <input type="password" value={password} onChange={handlePasswordChange} />
        <label>Phone Number:</label>
        <input type="number" value={phoneNumber} onChange={handlePhoneNumberChange} />
        <button className='bottombutton' type="submit" onClick={handleSignup}>
          Sign Up
        </button>
        {/* You can add the login button here if needed */}
      </form>
    </div>
  );
}

export default UserSignupLogin;
