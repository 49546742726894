import React, { useEffect, useState } from 'react';
import Chart from 'chart.js/auto';
import { useAuth } from '../userAuth'; 
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import './userStatsChart.css';

const UserAdminDailyMaterialChart = () => {
  const { isLoggedIn } = useAuth();
  const [chartData, setChartData] = useState(null);
  const isLocal = window.location.hostname === 'localhost';
  const baseUrl = isLocal ? 'http://localhost:3001' : 'https://crg-1-pakistan-bf9fced80b73.herokuapp.com';

  // Get user ID from location state
  const location = useLocation();
  const userId = location.state?.userId;
  const userInfo = location.state?.userInfo || {};
  const navigate = useNavigate();
  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login');
    }
  }, [isLoggedIn, navigate]);

  const handleEditClick = (contactId) => {
    // Navigate using the button concept instead of Link
    navigate(`/contact-details/${contactId}`, { state: { userInfo } });
  };


  const exportContactsToPDF = async (userId) => {
    try {
      const response = await axios.get(`${baseUrl}/backend-url/export-nosells-user-contacts-pdf/${userId}`, { responseType: 'blob' });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'attention-contacts.pdf');
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Error exporting contacts to PDF:', error);
    }
  };


  const exportLateContactsToPDF = async (userId) => {
    try {
      const response = await axios.get(`${baseUrl}/backend-url/export-late-user-contacts-pdf/${userId}`, { responseType: 'blob' });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'attention-contacts.pdf');
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Error exporting contacts to PDF:', error);
    }
  };

  const exportLateContactContactsToPDF = async (userId) => {
    try {
      const response = await axios.get(`${baseUrl}/backend-url/export-latecontact-user-contacts-pdf/${userId}`, { responseType: 'blob' });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'attention-contacts.pdf');
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Error exporting contacts to PDF:', error);
    }
  };
  
  

  useEffect(() => {
    if (userId) {
      // Fetch user's kilogram values by month from the backend
      const fetchChartData = async () => {
        try {
          const response = await axios.get(`${baseUrl}/api/user-kilogram-values`, {
            params: { id: userId }
          });
          setChartData(response.data);
        } catch (error) {
          console.error('Error fetching chart data:', error);
        }
      };      
  
      fetchChartData();
    }
  }, [baseUrl, userId]);
  

  useEffect(() => {
    if (chartData) {
      renderChart();
    }
  }, [chartData]);

  const renderChart = () => {
    const ctx = document.getElementById('userDailyMaterialChart').getContext('2d');
  
    // Check if a chart instance already exists and destroy it
    if (window.userDailyMaterialChartInstance) {
      window.userDailyMaterialChartInstance.destroy();
    }
  
    window.userDailyMaterialChartInstance = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: chartData.ceramicsMonths, // Use ceramicsMonths as labels
          datasets: [
            {
              label: 'Monthly Ceramic Kilograms',
              data: chartData.ceramicsKilograms, // Use ceramicsKilograms as data
              backgroundColor: 'rgba(54, 162, 235, 0.2)',
              borderColor: 'rgba(54, 162, 235, 1)',
              borderWidth: 1,
            },
            {
              label: 'Monthly WEEE Kilograms',
              data: chartData.weeeKilograms, // Use weeeKilograms as data
              backgroundColor: 'rgba(0, 255, 0, 0.2)', // Bright green with opacity
              borderColor: 'rgba(0, 255, 0, 1)', // Bright green                          
              borderWidth: 1,
            },
          ],
        },
        options: {
          plugins: {
            legend: {
              labels: {
                color: 'white' // Set the color of the dataset label text to white
              }
            }
          },
          scales: {
            y: {
              ticks: {
                color: 'white', // Set the font color of the y-axis labels to white
              },
              beginAtZero: true,
            },
            x: {
              ticks: {
                color: 'white', // Set the font color of the x-axis labels to white
              },
            },
          },
        },
      });
    };
    
  

  const renderCustomerInfo = () => {
    if (!chartData || !chartData.contactTotals) return null;
  
    const regularContacts = [];
    const attentionContacts = [];
    const lowerPriceContacts = [];
    const topCustomers = [];
  
    Object.keys(chartData.contactTotals).forEach(contactName => {
      const totalKilograms = chartData.contactTotals[contactName].totalCeramicsKilograms; // Updated to use totalCeramicsKilograms
      const numberOfPurchases = chartData.contactTotals[contactName].numberOfCeramicsPurchases; // Updated to use numberOfCeramicsPurchases
      const averageKgPerVisit = numberOfPurchases > 0 ? totalKilograms / numberOfPurchases : 0; // Calculate average kilograms per visit
  
      if (totalKilograms === 0) {
        const contactNumber = chartData.contactTotals[contactName].contactNumber;
        const contactId = chartData.contactTotals[contactName].contactId; // Include contactId here
        const lastContact = chartData.contactTotals[contactName].lastContact;
        attentionContacts.push({ contactName, contactNumber, contactId, numberOfPurchases, averageKgPerVisit, lastContact });
      } else {
        const totalUsdValue = chartData.contactTotals[contactName].totalCeramicsUsdValue; // Updated to use totalCeramicsUsdValue
        const margin = totalUsdValue !== 0 ? totalUsdValue / totalKilograms : 0; // Calculate margin
        const contactId = chartData.contactTotals[contactName].contactId; // Include contactId here
        const lastContact = chartData.contactTotals[contactName].lastContact;
      
        if (margin < 8) {
          lowerPriceContacts.push({ contactName, contactId, margin, numberOfPurchases, averageKgPerVisit, lastContact });
        } else if ((totalKilograms > 20 && margin >= 8 && averageKgPerVisit > 10) || (totalKilograms > 5 && margin >= 20)) {
          topCustomers.push({ contactName, totalKilograms, totalUsdValue, contactId, margin, numberOfPurchases, averageKgPerVisit, lastContact });
        } else {
          regularContacts.push({ contactName, totalKilograms, totalUsdValue, contactId, margin, numberOfPurchases, averageKgPerVisit, lastContact });
        }
      }      
    });
  
    return (
      <div className="customer-info-container-column">  


      <div className="customer-info-row">
      <div className='exportbutton' onClick={ () => exportContactsToPDF(userId)}>No Sells</div>   
      <div className='exportbutton' onClick={ () => exportLateContactsToPDF(userId)}>Late Delivery</div>   
      <div className='exportbutton' onClick={ () => exportLateContactContactsToPDF(userId)}>Late Contact</div> 
      </div>

      <div className="customer-info-container"> 
        <div className="customer-info-column">
        <div className='userStatsCustTitle'>Push Harder</div>
        {regularContacts.map((contactData, index) => (
  <div key={index} className='contactUserStatsInd'  onClick={() => handleEditClick(contactData.contactId)}>
    <div className='userstatscustheader'>{`${contactData.contactName.slice(0, 16)}`}</div>
    <div>{`Average: ${contactData.averageKgPerVisit.toFixed(0)} Kg`}</div>
    <div>{`Last: ${new Date(contactData.lastContact).toLocaleDateString()}`}</div>
  </div>
))}

        </div>
        <div className="customer-info-column">
        <div className='userStatsCustTitle'>Not Selling</div>
          {attentionContacts.map((contactData, index) => (
            <div key={index} className='contactUserStatsIndAttention' onClick={() => handleEditClick(contactData.contactId)}>
            <div className='userstatscustheader'>{`${contactData.contactName.slice(0, 16)}`}</div>
                <div>{`${contactData.contactNumber}`}</div>
                <div>{`Last: ${new Date(contactData.lastContact).toLocaleDateString()}`}</div>
            </div>
          ))}
        </div>
        <div className="customer-info-column">
        <div className='userStatsCustTitle'>Worse Performing</div>
          {lowerPriceContacts.map((contactData, index) => (
            <div key={index} className='contactUserStatsIndWorse' onClick={() => handleEditClick(contactData.contactId)}>
            <div className='userstatscustheader'>{`${contactData.contactName.slice(0, 16)}`}</div>
                <div>{`Average: ${contactData.averageKgPerVisit.toFixed(0)} Kg`}</div>
                <div>{`Last: ${new Date(contactData.lastContact).toLocaleDateString()}`}</div>
            </div>
          ))}
        </div>
        <div className="customer-info-column">
          <div className='userStatsCustTitle'>Top Customers</div>
          {topCustomers.map((contactData, index) => (
            <div key={index} className='contactUserStatsIndTop' onClick={() => handleEditClick(contactData.contactId)}>
            <div className='userstatscustheader'>{`${contactData.contactName.slice(0, 16)}`}</div>
                <div>{`Average: ${contactData.averageKgPerVisit.toFixed(0)} Kg`}</div>
                <div>{`Last: ${new Date(contactData.lastContact).toLocaleDateString()}`}</div>
            </div>
          ))}
        </div>
      </div>
      </div>
    );
  };
  
  
  return (
    <div className='userStatsChart'>
      <canvas id="userDailyMaterialChart"></canvas>
      <div className='userStatsChartSecond'>
        {renderCustomerInfo()}
      </div>
    </div>
  );
};

export default UserAdminDailyMaterialChart;
