import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './cartpopup.css';

const PopupSubmit = ({ cartId }) => {
  const [showPopup, setShowPopup] = useState(true);
  const [cartData, setCartData] = useState(null);
  const [numberInput, setNumberInput] = useState('');
  const [messageInput, setMessageInput] = useState('');
  const [phoneNumberInput, setPhoneNumberInput] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const isLocal = window.location.hostname === 'localhost';
  const baseUrl = isLocal ? 'http://localhost:3003' : 'https://crg-1-pakistan-bf9fced80b73.herokuapp.com';

  const handlePhoneNumberChange = event => {
    const value = event.target.value;
    const sanitizedValue = value.replace(/[^0-9+]/g, '');
    setPhoneNumberInput(sanitizedValue);
  };

  useEffect(() => {
    fetch(`${baseUrl}/api/cartsubmit/${cartId}`)
      .then(response => response.json())
      .then(data => {
        setCartData(data[0]);
        console.log('cart', data[0]);
      })
      .catch(error => {
        console.error('Error fetching cart data:', error);
      });
  }, [cartId, baseUrl]);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleFormSubmit = event => {
    event.preventDefault();

    // Send email using an API call
    fetch(`${baseUrl}/api/sendEmail`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        cartId: cartId,
        numberInput: numberInput,
        messageInput: messageInput,
        phoneNumberInput: phoneNumberInput,
        startDate: startDate,
        endDate: endDate,
        kilogram: cartData?.kilogram || 0, 
        totalValue: cartData?.totalValue || 0 
      })
    })
      .then(response => {
        if (response.ok) {
          console.log('Email sent successfully!');
          handleClosePopup();
        } else {
          console.error('Failed to send email.');
        }
      })
      .catch(error => {
        console.error('Error sending email:', error);
      });
  };

  const handleNumberChange = event => {
    setNumberInput(event.target.value);
  };

  const handleMessageChange = event => {
    setMessageInput(event.target.value);
  };

  return (
    <>
      {showPopup && (
        <div className="popup2">
          <div className="popup-inner2">
            {cartData ? (
              <>
                <p className="popup-message">Cart Name: {cartData.cartName}</p>
                <p className='values1'>Total Value: {cartData.totalValue.toFixed(2)}</p>
                <p className='values2'>Kilograms: {cartData.kilogram.toFixed(2)}</p>
              </>
            ) : (
              <p>Loading cart data...</p>
            )}
            <form onSubmit={handleFormSubmit}>
            <div className='biggerheader'>Available for pickup</div>
            <div className='inputheader'>Earliest Date:</div>
              <DatePicker
                    id="startDate"
                    name="startDateInput" // Add the name attribute
                    selected={startDate}
                    onChange={date => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    dateFormat="yyyy-MM-dd"
                    className="date-input"
                    />
              <div className='inputheader'>Latest Date:</div>
                    <DatePicker
                    id="endDate"
                    name="endDateInput" // Add the name attribute
                    selected={endDate}
                    onChange={date => setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    dateFormat="yyyy-MM-dd"
                    className="date-input"
                    />
              <div className='inputheader'>Wanted Price</div> 
              <input
                type="number"
                id="numberInput"
                value={numberInput}
                onChange={handleNumberChange}
              />
              <div className='inputheader'>Phone Number:</div>
              <input
                type="text"
                id="phoneNumberInput"
                value={phoneNumberInput}
                onChange={handlePhoneNumberChange}
              />
              <div className='inputheader'>Message:</div>
              <textarea
                className="textarea"
                id="messageInput"
                value={messageInput}
                onChange={handleMessageChange}
                minLength={50}
              />
              <button className="cartpopup-close2" type="submit">
                Send
              </button>
              <button className="cartpopup-close2" onClick={handleClosePopup}>
                Close
              </button>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default PopupSubmit;

