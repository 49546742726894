import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './WeeMainComponent.css';

function WeeMainComponent() {
  const [documents, setDocuments] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null); // Track the selected image
  const [searchQuery, setSearchQuery] = useState(''); // Track the search query
  const navigate = useNavigate();
  const location = useLocation();
  const title = location.state?.title;
  const isLocal = window.location.hostname === 'localhost';
  const baseUrl = isLocal ? 'http://localhost:3003' : 'https://crg-1-pakistan-bf9fced80b73.herokuapp.com';

  useEffect(() => {
    fetch(`${baseUrl}/api/documents/home`)
      .then(response => response.json())
      .then(data => {
        const sortedDocuments = data.sort((a, b) => a.weeName.localeCompare(b.weeName));
        setDocuments(sortedDocuments);
      })
      .catch(error => {
        console.error('Error fetching documents:', error);
      });
  }, [baseUrl]);

  const handleCardClick = (documentId) => {
    navigate(`/admin/${documentId}`);
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredDocuments = documents.filter(document =>
    document.weeName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const sliderSettings = {
    infinite: true,
    slidesToScroll: 1,
    autoplay: false,
  };

  return (
    <div className='weemain'>
    <div className="wee">
      <div className="search-container">
        <input
          type="text"
          placeholder="Search"
          value={searchQuery}
          onChange={handleSearchChange}
        />
      </div>
      <div className="card-container">
        {filteredDocuments.map(document => (
          <div key={document._id} className="card">
            <h3>{document.weeName}</h3>
            {document.weeImageOne && (
              <Slider className="slider-container" {...sliderSettings}>
                <div onClick={() => handleImageClick(document.weeImageOne)}>
                  <img src={document.weeImageOne} alt="" />
                </div>
                {document.weeImageTwo && (
                  <div onClick={() => handleImageClick(document.weeImageTwo)}>
                    <img src={document.weeImageTwo} alt="" />
                  </div>
                )}
                {document.weeImageThree && (
                  <div onClick={() => handleImageClick(document.weeImageThree)}>
                    <img src={document.weeImageThree} alt="" />
                  </div>
                )}
              </Slider>
            )}
            <p>EGP {document.egp.toFixed(0)} / KG</p>
            {title === 'Admins' && (
              <button className="button" onClick={() => handleCardClick(document._id)}>
                Go to Admin
              </button>
            )}
          </div>
        ))}
      </div>
      {selectedImage && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={handleCloseModal}>
              &times;
            </span>
            <img className="enlarged-image" src={selectedImage} alt="" />
          </div>
        </div>
      )}
    </div>
    </div>
  );
}

export default WeeMainComponent;

