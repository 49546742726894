import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from '../userAuth'; 
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-modal'; 
import { faEdit, faComputer, faCar, faPlus, faChartGantt } from '@fortawesome/free-solid-svg-icons';
import './contactList.css';

Modal.setAppElement('#root');

const ContactList = () => {
  const { isLoggedIn } = useAuth();
  const [showPerformanceModal, setShowPerformanceModal] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [materialFilter, setMaterialFilter] = useState('');
  const [filterStatus, setFilterStatus] = useState('All');
  const [sortOption, setSortOption] = useState('nextContact'); // Default to 'nextContact'
  const [userFilter, setUserFilter] = useState(''); 
  const [usersList, setUsersList] = useState([]);
  const location = useLocation();
  const [searchType, setSearchType] = useState('name');
  const userInfo = location.state?.userInfo || {};
  const navigate = useNavigate();
  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login');
    }
  }, [isLoggedIn, navigate]);
  const isLocal = window.location.hostname === 'localhost';
  const baseUrl = isLocal ? 'http://localhost:3001' : 'https://crg-1-pakistan-bf9fced80b73.herokuapp.com';
  const [localStorageSortOption, setLocalStorageSortOption] = useState(() => {
    const storedSortOption = localStorage.getItem('contactListSortOption');
    return storedSortOption || 'nextContact';
  });

  // New state to store user filter from localStorage
  const [localStorageUserFilter, setLocalStorageUserFilter] = useState(() => {
    const storedUserFilter = localStorage.getItem('contactListUserFilter');
    return storedUserFilter || '';
  });

  const handleClickAdmin = () => {
    // Show the modal
    setShowPerformanceModal(true);
  };

  // Function to close the modal
  const handleCloseModal = () => {
    // Close the modal
    setShowPerformanceModal(false);
  };

  // Update the stored user filter whenever the state changes
  useEffect(() => {
    localStorage.setItem('contactListUserFilter', localStorageUserFilter);
  }, [localStorageUserFilter]);

  // Set userFilter state based on localStorage when the component mounts
  useEffect(() => {
    setUserFilter(localStorageUserFilter);
  }, [localStorageUserFilter]);

  const handleUserFilterChange = (e) => {
    // Update both state and localStorage when the user filter changes
    setUserFilter(e.target.value);
    setLocalStorageUserFilter(e.target.value);
  };

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        let contactsEndpoint;

        if (userInfo.position === 'Sales') {
          contactsEndpoint = `${baseUrl}/api/user-contacts/${userInfo.id}`;
        } else {
          contactsEndpoint = `${baseUrl}/api/contacts`;
        }

        const response = await axios.get(contactsEndpoint);

        const sortedContacts = response.data.sort((a, b) => {
          const dateA = localStorageSortOption === 'nextContact' ? new Date(a.nextContact) : new Date(a.lastContact);
          const dateB = localStorageSortOption === 'nextContact' ? new Date(b.nextContact) : new Date(b.lastContact);

          return localStorageSortOption === 'nextContact' ? dateA - dateB : dateB - dateA;
        });

        setContacts(sortedContacts);
      } catch (error) {
        console.error('Error fetching contacts:', error);
      }
    };

    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/usersall`);
        setUsersList(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };
    fetchContacts();
    fetchUsers();

    // Save the sorting option to localStorage when it changes
    localStorage.setItem('contactListSortOption', localStorageSortOption);
  }, [baseUrl, userInfo.id, userInfo.position, localStorageSortOption, userFilter]);



  const resetFilters = () => {
    setSearchQuery('');
    setMaterialFilter('');
    setUserFilter('');
    setFilterStatus('All');
    setSortOption('nextContact');
    setLocalStorageUserFilter('');
    setLocalStorageSortOption('nextContact');
  };
  
  const isNextContactOlder = (nextContact) => {
    if (!nextContact) return false;

    const nextContactDate = new Date(nextContact);
    const presentDate = new Date();
    // Set hours, minutes, seconds, and milliseconds to 0 for accurate date comparison
    nextContactDate.setHours(0, 0, 0, 0);
    presentDate.setHours(0, 0, 0, 0);

    return nextContactDate < presentDate;
  };

  const isNextContactDueToday = (nextContact) => {
    if (!nextContact) return false;

    const nextContactDate = new Date(nextContact);
    const presentDate = new Date();
    // Set hours, minutes, seconds, and milliseconds to 0 for accurate date comparison
    nextContactDate.setHours(0, 0, 0, 0);
    presentDate.setHours(0, 0, 0, 0);

    return nextContactDate.getTime() === presentDate.getTime();
  };

  const handleSortByLastContact = () => {
    setLocalStorageSortOption('lastContact');
  };

  const handleSortByNextContact = () => {
    setLocalStorageSortOption('nextContact');
  };

  const renderMaterialIcon = (materialType) => {
    switch (materialType) {
      case 'Cats':
        return <FontAwesomeIcon className='materialIcon' icon={faCar} />;
      case 'Weee':
        return <FontAwesomeIcon className='materialIcon' icon={faComputer} />;
      case 'Both':
        return (
          <>
            <FontAwesomeIcon className='materialIcon' icon={faCar} />
            <FontAwesomeIcon className='materialIcon' icon={faComputer} />
          </>
        );
      default:
        return null;
    }
  };

// Filter contacts based on search query
const filteredContacts = contacts.filter(contact => {
  const lowerCaseName = contact.name.toLowerCase();
  const lowerCaseNumber = contact.number.toLowerCase();

  const includesName = lowerCaseName.includes(searchQuery.toLowerCase());
  const includesNumber = lowerCaseNumber.includes(searchQuery.toLowerCase());

  return (
    (searchType === 'name' && includesName) ||
    (searchType === 'number' && includesNumber)
  ) &&
  (materialFilter === '' || contact.materialType === materialFilter) &&
  (userFilter === '' || (contact.user && contact.user.id === userFilter)) &&
  ((filterStatus === 'Overdue' && isNextContactOlder(contact.nextContact)) ||
    (filterStatus === 'Current' && isNextContactDueToday(contact.nextContact)) ||
    (filterStatus === 'Future' && !isNextContactOlder(contact.nextContact) && !isNextContactDueToday(contact.nextContact)) ||
    filterStatus === 'All');
});

  
  const handleEditClick = (contactId) => {
    // Navigate using the button concept instead of Link
    navigate(`/contact-details/${contactId}`, { state: { userInfo } });
  };

  const handleCalendarClick = () => {
    navigate('/calendar-view', { state: { userInfo } });
  };

  const handleCalendarPredictClick = () => {
    navigate('/calendar-predict-view', { state: { userInfo } });
  };


  const handleAddCustomerClick = () => {
    navigate('/contact-form', { state: { userInfo } });
  };

  const handleClickUserReport = () => {
    navigate('/user-report', { state: { userInfo } });

  };

  const handleClickAdminUserReport = (selectedUserId) => {
    const selectedUser = usersList.find(user => user._id === selectedUserId);
    if (selectedUser) {
      // If the selected user is found, navigate to the user report page with the userId
      navigate('/user-report-admin', { state: { userId: selectedUser._id } });
    } else {
      console.error('Selected user not found');
    }
  };
  
  
  

  return (
    <div className='contactListMain'>
      {isLoggedIn && (
      <div className='contactListSecond'>
                <div className='filterButtons'>
            <button onClick={handleAddCustomerClick} className='contactButton'>Add Customer</button>
          <button onClick={handleCalendarClick} className='contactButton'>Calendar</button>
          <button onClick={handleCalendarPredictClick} className='contactButton'>Predict</button>
          {userInfo.position === 'Sales' &&  (
        <>
          <button onClick={handleClickUserReport} className='contactButton'>Performance</button>
          </>
      )}
          {userInfo.position !== 'Sales' && userInfo.position !== 'Center' &&  (
        <>
          <button  className='contactButton' onClick={handleClickAdmin}>Admin</button>
          {showPerformanceModal && <Modal onClose={handleCloseModal} />}
        </>
      )}
<Modal
  isOpen={showPerformanceModal}
  onRequestClose={() => setShowPerformanceModal(false)} // Corrected the function name
  contentLabel='Add Contact'
  style={{
    overlay: {
      top: '80px',
      width: '100%',
      background: 'black',
    },
    content: {
      margin: 'auto',
    },
  }}
>
  <div>
    <div  className='ulAssignUser'>
      <h2>Assign User</h2>
      <div className='modaluserlist'>
        {usersList.map((user) => (
          <div className='modaluserind' key={user._id}>
            {user.name} 
            <FontAwesomeIcon className='plusiconmodal' icon={faChartGantt} onClick={() => handleClickAdminUserReport(user._id)} /> {/* Pass user._id as argument */}
          </div>
        ))}
      </div>
      <button className='buttonModalAssignUser' onClick={() => setShowPerformanceModal(false)}>Close</button> {/* Corrected the function name */}
    </div>
  </div>
</Modal>




    </div>
      <h2>Contact List</h2>
        <div className='filterButtons'>
        <button className='contactButton' onClick={() => setMaterialFilter('Weee')}>Weee</button>
          <button className='contactButton' onClick={() => setMaterialFilter('Cats')}>Cats</button>
          <button className='contactButton' onClick={() => setMaterialFilter('Both')}>Both</button>
          <button className='contactButton' onClick={resetFilters}>Reset Filters</button>
        </div>
        <div className='filterButtons'>
           <button className='contactButton' onClick={() => setFilterStatus('Overdue')}>Overdue</button>
           <button className='contactButton' onClick={() => setFilterStatus('Current')}>Current</button>
           <button className='contactButton' onClick={() => setFilterStatus('Future')}>Future</button>
           <button className='contactButton' onClick={handleSortByLastContact}>Last</button>
          <button className='contactButton' onClick={handleSortByNextContact}>Next</button>
        </div>

        {userInfo.position !== 'Sales' &&  (
        <>
        <div className='filterButtons'>
        <select onChange={handleUserFilterChange} value={userFilter}>
        <option value="">All Users</option>
        {usersList.map((user) => (
          <option key={user._id} value={user._id}>{user.name}</option>
        ))}
      </select>
        </div>
        </>
      )}

        <div className='search-options'>
          <label>
            <input
              type="radio"
              name="searchType"
              value="name"
              checked={searchType === 'name'}
              onChange={() => setSearchType('name')}
            />
            Search by Name
          </label>
          <label>
            <input
              type="radio"
              name="searchType"
              value="number"
              checked={searchType === 'number'}
              onChange={() => setSearchType('number')}
            />
            Search by Number
          </label>
        </div>
        <input
          className='customerSearch'
          type="text"
          placeholder={searchType === 'name' ? 'Search by name' : 'Search by number'}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <div className='contactListGrid'>
          {filteredContacts.map((contact) => (
            <div
              key={contact._id}
              className={`contactRow 
                ${isNextContactOlder(contact.nextContact) ? 'redBackground' : ''}
                ${isNextContactDueToday(contact.nextContact) ? 'greenBackground' : ''}
                ${!isNextContactOlder(contact.nextContact) && !isNextContactDueToday(contact.nextContact) ? 'orangeBackground' : ''}
              `}
            >
              <div className='dataText'>
                <strong>{contact.typeOfContact}:</strong>{' '}
                {sortOption === 'nextContact'
                  ? (contact.nextContact ? new Date(contact.nextContact).toLocaleDateString('en-GB') : 'N/A')
                  : (contact.lastContact ? new Date(contact.lastContact).toLocaleDateString('en-GB') : 'N/A')}
              </div>
              <div className='dataText'>
                <strong>{contact.name}</strong>
              </div>
              <div className='dataText'>
                <strong>{contact.user && contact.user.name}</strong>
              </div>
              <div className='materialIconsBox'>
                <strong>{renderMaterialIcon(contact.materialType)}</strong>
              </div>
              <div className='editIcon'>
                <button onClick={() => handleEditClick(contact._id)}>
                  <FontAwesomeIcon icon={faEdit} />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      )}
    </div>
  );
};

export default ContactList;
