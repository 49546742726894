import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../userAuth'; 
import { useParams, useNavigate, useLocation, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus, faComputer, faCar, faChartLine, faCalculator, faPhone, faPerson } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal'; 
import './contactDetails.css';

Modal.setAppElement('#root');

const ContactDetails = () => {
  const { isLoggedIn } = useAuth();
  const [weeeKgInput, setWeeeKgInput] = useState('');
  const [ceramicsKgInput, setCeramicsKgInput] = useState('');
  const [borrowedInput, setBorrowedInput] = useState('');
  const [returnedInput, setReturnedInput] = useState('');
  const borrowedValue = parseFloat(borrowedInput) || 0;
  const returnedValue = parseFloat(returnedInput) || 0;
  const location = useLocation();
  const userPosition = location.state?.userInfo?.position;
  const title = localStorage.title;
  const [weeeUsdValueInput, setWeeeUsdValueInput] = useState('');
  const [ceramicsUsdValueInput, setCeramicsUsdValueInput] = useState('');
  const [weeeData, setWeeeData] = useState([]);
  const [ceramicsData, setCeramicsData] = useState([]); 
  const [isEditingCatalogue, setIsEditingCatalogue] = useState(false);
  const [editedCatalogue, setEditedCatalogue] = useState('NA');
  const [contact, setContact] = useState({});
  const [newNote, setNewNote] = useState('');
  const [editedMaterialType, setEditedMaterialType] = useState('');
  const [showContactModal, setShowContactModal] = useState(false);
  const [isEditingNextContact, setIsEditingNextContact] = useState(false);
  const [nextContactDate, setNextContactDate] = useState('');
  const [isEditingTypeOfContact, setIsEditingTypeOfContact] = useState(false);
  const [editedTypeOfContact, setEditedTypeOfContact] = useState('');
  const [isEditingField, setIsEditingField] = useState({
    name: false,
    address: false,
    number: false,
  });
  const [editedFields, setEditedFields] = useState({
    name: '',
    address: '',
    number: '',
  });
  const [contacts, setContacts] = useState([]);
  const [isEditingMaterialType, setIsEditingMaterialType] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login');
    }
  }, [isLoggedIn, navigate]);
  const { id } = useParams();
  const isLocal = window.location.hostname === 'localhost';
  const baseUrl = isLocal ? 'http://localhost:3001' : 'https://crg-1-pakistan-bf9fced80b73.herokuapp.com';


// Function to calculate the expected kilograms based on historical data
const calculateExpectedKg = (purchases) => {
  // Assuming purchases is an array of objects with 'kilograms' field
  const totalKg = purchases.reduce((total, purchase) => total + purchase.kilograms, 0);
  return totalKg / purchases.length; // Average kilograms per purchase
};

const calculateFrequency = (purchases) => {
  if (!purchases || purchases.length <= 1) return 30; // Default to 30 days if there's only one or no purchase
  
  const startDate = new Date(purchases[0].date);
  const endDate = new Date(purchases[purchases.length - 1].date);
  
  const daysDifference = Math.abs(endDate - startDate) / (1000 * 60 * 60 * 24); // Convert milliseconds to days
  console.log('Days Difference:', daysDifference);
  
  const averageFrequencyInDays = daysDifference / (purchases.length - 1); // Divide by number of purchases minus one
  console.log('Average Frequency:', averageFrequencyInDays);
  
  return averageFrequencyInDays;
};


const predictNextPurchaseDate = (lastPurchaseDate, averageFrequencyInDays) => {
  const currentDate = new Date(); // Get the current date
  const oneMonthAgo = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, currentDate.getDate());
  if (!lastPurchaseDate || !averageFrequencyInDays) return oneMonthAgo;

  const nextPurchaseDate = new Date(lastPurchaseDate);
  console.log('Next Purchase Date Before:', nextPurchaseDate);
  nextPurchaseDate.setDate(nextPurchaseDate.getDate() + Math.round(averageFrequencyInDays)); // Round to the nearest whole number of days
  console.log('Next Purchase Date After:', nextPurchaseDate);
  return nextPurchaseDate.toLocaleDateString();
};




  useEffect(() => {
    const fetchContactDetails = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/contacts/${id}`);
        setContact(response.data);
  
        // Extracting weee and ceramics data from the contact
        const weeeData = response.data?.weee?.indWeeePurchases || [];
        const ceramicsData = response.data?.ceramics?.indCeramicsPurchases || [];
  
        setWeeeData(weeeData);
        setCeramicsData(ceramicsData);
      } catch (error) {
        console.error('Error fetching contact details:', error);
      }
    };
  
    fetchContactDetails();
  }, [id, baseUrl]);

  const handleAddContact = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/users`);
      setContacts(response.data);
      setShowContactModal(true);
    } catch (error) {
      console.error('Error fetching contacts:', error);
    }
  };

  const handleAssociateContact = async (userId) => {
    try {
      // Fetch the user details
      const userResponse = await axios.get(`${baseUrl}/api/users/${userId}`);
      const userName = userResponse.data.name;
  
      // Send a request to associate the user with the selected contact
      await axios.put(`${baseUrl}/api/user/addcontact/${userId}`, { contactId: id, userName });
  
      setShowContactModal(false);
  
      // Fetch the updated contact details
      const response = await axios.get(`${baseUrl}/api/contacts/${id}`);
      setContact(response.data);
    } catch (error) {
      console.error('Error associating contact with user:', error);
    }
  };
  
  
  

  const handleSaveNote = async () => {
    try {
      if (newNote.trim() === '') {
        console.warn('Cannot save an empty note.');
        return;
      }
  
      const currentDate = new Date();

    // Update the contact with the new note, borrowed, returned, and set lastContact to the current date
    await axios.put(`${baseUrl}/api/contacts/${id}`, {
      notes: [...contact.notes, { content: newNote, createdAt: currentDate }],
      lastContact: currentDate,
      weee: await updateWeee(),
      ceramics: await updateCeramics(),
      debt: (contact.debt || 0) + (parseFloat(borrowedInput) || 0) - (parseFloat(returnedInput) || 0),
    });
  
      // Fetch the updated contact details
      const response = await axios.get(`${baseUrl}/api/contacts/${id}`);
      setContact(response.data);
  
      // Fetch the corresponding CRG document
      const crgResponse = await axios.get(`${baseUrl}/api/crg`);
      const crgData = crgResponse.data || {};
  
      const crgUpdateData = {
        weeeCrg: updateWeeeCrg(crgData.weeeCrg),
        ceramicsCrg: updateCeramicsCrg(crgData.ceramicsCrg),
        debtCrg: updateDebtCrg(crgData.debtCrg),
        debtCrgTotalUsd: (crgData.debtCrgTotalUsd || 0) + (parseFloat(borrowedInput) || 0) - (parseFloat(returnedInput) || 0),
      };

      // Update the CRG document
      await axios.put(`${baseUrl}/api/crg`, crgUpdateData);
  
      setNewNote('');
      setWeeeKgInput('');
      setCeramicsKgInput('');
      setCeramicsUsdValueInput('');
      setWeeeUsdValueInput('');
      setBorrowedInput('');
      setReturnedInput('');
  
      // Fetch the updated contact details again
      const updatedResponse = await axios.get(`${baseUrl}/api/contacts/${id}`);
      setContact(updatedResponse.data);
    // Calculate the next purchase date after all updates
    const lastCeramicsPurchaseDate = updatedResponse.data.ceramics?.indCeramicsPurchases.length > 0
      ? updatedResponse.data.ceramics?.indCeramicsPurchases[updatedResponse.data.ceramics?.indCeramicsPurchases.length - 1].date
      : null;
    const averageFrequency = calculateFrequency(updatedResponse.data.ceramics?.indCeramicsPurchases);
    const nextPurchaseDate = predictNextPurchaseDate(lastCeramicsPurchaseDate, averageFrequency);
    console.log('nextPurchase', nextPurchaseDate);
    // Update the contact with the calculated next purchase date
    await axios.put(`${baseUrl}/api/contacts/${id}`, { nextPurchase: nextPurchaseDate });

  } catch (error) {
    console.error('Error adding note:', error);
  }
};
  
  
  const updateWeee = () => {
    const weee = contact.weee;
  
    if (weee && weeeKgInput.trim() !== '') {
      return {
        kilogram: (weee.kilogram || 0) + parseFloat(weeeKgInput),
        purchases: (weee.purchases || 0) + 1,
        indWeeePurchases: [
          ...weee.indWeeePurchases,
          {
            kilograms: parseFloat(weeeKgInput),
            usdValue: parseFloat(weeeUsdValueInput), // Add this line to save usdValue
          },
        ],
      };
    }
    return weee;
  };
  
  const updateCeramics = () => {
    const ceramics = contact.ceramics;
  
    if (ceramics && ceramicsKgInput.trim() !== '') {
      return {
        kilogram: (ceramics.kilogram || 0) + parseFloat(ceramicsKgInput),
        purchases: (ceramics.purchases || 0) + 1,
        indCeramicsPurchases: [
          ...ceramics.indCeramicsPurchases,
          {
            kilograms: parseFloat(ceramicsKgInput),
            usdValue: parseFloat(ceramicsUsdValueInput), // Add this line to save usdValue
          },
        ],
      };
    }
    return ceramics;
  };


  const updateWeeeCrg = (weeeCrg) => {
    if (weeeCrg && weeeKgInput.trim() !== '') {
      return {
        kilogramCrg: (weeeCrg.kilogramCrg || 0) + parseFloat(weeeKgInput),
        purchasesCrg: (weeeCrg.purchasesCrg || 0) + 1,
        usdValueCrg: (weeeCrg.usdValueCrg || 0) + parseFloat(weeeUsdValueInput),
        indWeeeCrg: [
          ...weeeCrg.indWeeeCrg,
          {
            kilogramsCrg: parseFloat(weeeKgInput),
            usdValueCrg: parseFloat(weeeUsdValueInput),
            dateCrg: new Date(),
            contactCrg: {
              id: contact._id,
              name: contact.name,
            },
          },
        ],
      };
    }
    return weeeCrg;
  };
  
  const updateCeramicsCrg = (ceramicsCrg) => {
    if (ceramicsCrg && ceramicsKgInput.trim() !== '') {
      return {
        kilogramCrg: (ceramicsCrg.kilogramCrg || 0) + parseFloat(ceramicsKgInput),
        purchasesCrg: (ceramicsCrg.purchasesCrg || 0) + 1,
        usdValueCrg: (ceramicsCrg.usdValueCrg || 0) + parseFloat(ceramicsUsdValueInput),
        indCeramicsCrg: [
          ...ceramicsCrg.indCeramicsCrg,
          {
            kilogramsCrg: parseFloat(ceramicsKgInput),
            usdValueCrg: parseFloat(ceramicsUsdValueInput),
            dateCrg: new Date(),
            contactCrg: {
              id: contact._id,
              name: contact.name,
            },
          },
        ],
      };
    }
    return ceramicsCrg;
  };
  
  const updateDebtCrg = (debtCrg) => {
    if (borrowedValue !== 0 || returnedValue !== 0) {
      const newDebtEntry = {
        usdValueCrg: borrowedValue - returnedValue,
        contactCrg: {
          id: contact._id,
          name: contact.name,
        },
      };
      return [...debtCrg, newDebtEntry];
    }
  
    return debtCrg;
  };
  
  

  

  const handleSaveNextContact = async () => {
    try {
      if (!nextContactDate) {
        console.warn('Next contact date cannot be empty.');
        return;
      }

      const updatedContact = await axios.put(`${baseUrl}/api/contacts/${id}`, {
        nextContact: new Date(nextContactDate).toISOString(),
      });

      setContact(updatedContact.data);
      setIsEditingNextContact(false);
    } catch (error) {
      console.error('Error updating next contact date:', error);
    }
  };

  const handleEditField = (fieldName) => {
    setIsEditingField((prev) => ({ ...prev, [fieldName]: true }));
    setEditedFields((prev) => ({ ...prev, [fieldName]: contact[fieldName] }));
  };

  const handleSaveField = async (fieldName) => {
    try {
      if (editedFields[fieldName] === '') {
        console.warn(`${fieldName} cannot be empty.`);
        return;
      }

      await axios.put(`${baseUrl}/api/contacts/${id}`, {
        [fieldName]: editedFields[fieldName],
      });

      setIsEditingField((prev) => ({ ...prev, [fieldName]: false }));
      setContact((prev) => ({ ...prev, [fieldName]: editedFields[fieldName] }));
    } catch (error) {
      console.error(`Error updating ${fieldName}:`, error);
    }
  };


  const handleSaveTypeOfContact = async () => {
    try {
      if (editedTypeOfContact === '') {
        console.warn('Type of contact cannot be empty.');
        return;
      }

      // Assuming you want to restrict the typeOfContact to 'Call' or 'Visit'
      if (editedTypeOfContact !== 'Call' && editedTypeOfContact !== 'Visit' && editedTypeOfContact !== 'Delivery') {
        console.warn('Invalid type of contact.');
        return;
      }

      await axios.put(`${baseUrl}/api/contacts/${id}`, {
        typeOfContact: editedTypeOfContact,
      });

      setIsEditingTypeOfContact(false);
      setContact((prev) => ({ ...prev, typeOfContact: editedTypeOfContact }));
    } catch (error) {
      console.error('Error updating type of contact:', error);
    }
  };

  const handleNoAnswer = async () => {
    try {
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
  
      // Increment the noAnswerCount
      await axios.put(`${baseUrl}/api/contacts/${id}`, {
        nextContact: tomorrow.toISOString(),
        notes: [
          ...contact.notes,
          { content: 'No Contact', createdAt: new Date() },
        ],
        noAnswerCount: contact.noAnswerCount + 1,
      });
  
      // Fetch the updated contact details
      const response = await axios.get(`${baseUrl}/api/contacts/${id}`);
      setContact(response.data);
  
      // Check if noAnswerCount is 5 or more
      if (response.data.noAnswerCount >= 5) {
        // If yes, add the contact to the assigned user's lostCustomers field
        const userId = response.data.user ? response.data.user.id : null;
        if (userId) {
          await axios.put(`${baseUrl}/api/users/${userId}/addlostcustomer/${id}`);
        }
      }
  
    } catch (error) {
      console.error('Error handling no answer:', error);
    }
  };
  

  const renderMaterialIcon = (materialType) => {
    switch (materialType) {
      case 'Cats':
        return <FontAwesomeIcon className='iconMaterial' icon={faCar} />;
      case 'Weee':
        return <FontAwesomeIcon className='iconMaterial' icon={faComputer} />;
      case 'Both':
        return (
          <>
            <FontAwesomeIcon className='iconMaterial' icon={faCar} />
            <FontAwesomeIcon className='iconMaterial' icon={faComputer} />
          </>
        );
      default:
        return null;
    }
  };
  
  const handleSaveMaterialType = async () => {
    try {
      if (typeof editedMaterialType === 'undefined' || editedMaterialType === '') {
        console.warn('Material type cannot be empty.');
        return;
      }

      await axios.put(`${baseUrl}/api/contacts/${id}`, {
        materialType: editedMaterialType,
      });

      setIsEditingMaterialType(false);
      setContact((prev) => ({ ...prev, materialType: editedMaterialType }));
    } catch (error) {
      console.error('Error updating material type:', error);
    }
  };

  const handleSaveCatalogue = async () => {
    try {
      if (editedCatalogue === '') {
        console.warn('Catalogue cannot be empty.');
        return;
      }
  
      await axios.put(`${baseUrl}/api/contacts/${id}`, {
        catalogue: editedCatalogue,
      });
  
      setIsEditingCatalogue(false);
      setContact((prev) => ({ ...prev, catalogue: editedCatalogue }));
    } catch (error) {
      console.error('Error updating catalogue:', error);
    }
  };
  


  return (
    <div className='contactDetailsMain'>
      <div className='contactDetailsSecond'>

      {title !== "Admin" && (
            <div className='contactDetailsBox'>
                  {contact.name} <FontAwesomeIcon className='editIcon' icon={faPerson} />
            </div>
          )}



      {title === "Admin" && (
      <div className='contactDetailsBox'>
          <strong>Name:</strong>{' '}
          {isEditingField.name ? (
            <div>
              <input
                type="text"
                value={editedFields.name}
                onChange={(e) => setEditedFields((prev) => ({ ...prev, name: e.target.value }))}
              />
              <button onClick={() => handleSaveField('name')}>Save</button>
            </div>
          ) : (
            <>
              {contact.name} <FontAwesomeIcon className='editIcon' icon={faEdit} onClick={() => handleEditField('name')} />
            </>
          )}
        </div>
                  )}


            {title !== "Admin" && (
            <div className='contactDetailsBox'>
                  {contact.number} <FontAwesomeIcon className='editIcon' icon={faPhone} />
            </div>
          )}


        
           
            {title === "Admin" && (
            <div className='contactDetailsBox'>
              <strong>Phone:</strong>{' '}
              {isEditingField.number ? (
                <div>
                  <input
                    type="text"
                    value={editedFields.number}
                    onChange={(e) => setEditedFields((prev) => ({ ...prev, number: e.target.value }))}
                  />
                  <button onClick={() => handleSaveField('number')}>Save</button>
                </div>
              ) : (
                <>
                  {contact.number} <FontAwesomeIcon className='editIcon' icon={faEdit} onClick={() => handleEditField('number')} />
                </>
              )}
            </div>
          )}





        <div className='contactDetailsBox'>
          <strong>Address:</strong>{' '}
          {isEditingField.address ? (
            <div>
              <input
                type="text"
                value={editedFields.address}
                onChange={(e) => setEditedFields((prev) => ({ ...prev, address: e.target.value }))}
              />
              <button onClick={() => handleSaveField('address')}>Save</button>
            </div>
          ) : (
            <>
              {contact.address} <FontAwesomeIcon className='editIcon' icon={faEdit} onClick={() => handleEditField('address')} />
            </>
          )}
        </div>
        <div className='contactDetailsBox'>
        <strong>Next:</strong>{' '}
        {isEditingNextContact ? (
          <div>
            <input
              type="date"
              value={nextContactDate}
              onChange={(e) => setNextContactDate(e.target.value)}
            />
            <button onClick={handleSaveNextContact}>Save</button>
          </div>
        ) : (
          <>
            {contact.nextContact ? (
              <>
                {new Date(contact.nextContact).toLocaleDateString()}{' '}
                 <FontAwesomeIcon
                  className='editIcon' icon={faEdit}
                  onClick={() => {
                    setIsEditingNextContact(true);
                    setNextContactDate(new Date(contact.nextContact).toISOString().split('T')[0]);
                  }}
                />
              </>
            ) : (
              'N/A'
            )}
          </>
        )}
      </div>
      <div className='contactDetailsBox'>
          <strong>Type:</strong>{' '}
          {isEditingTypeOfContact ? (
            <div>
              <select
                value={editedTypeOfContact}
                onChange={(e) => setEditedTypeOfContact(e.target.value)}
              >
                <option value="Call">Call</option>
                <option value="Visit">Visit</option>
                <option value="Delivery">Delivery</option>
              </select>
              <button onClick={handleSaveTypeOfContact}>Save</button>
            </div>
          ) : (
            <>
              {contact.typeOfContact} <FontAwesomeIcon className='editIcon' icon={faEdit} onClick={() => setIsEditingTypeOfContact(true)} />
            </>
          )}
        </div>


        <Modal
  isOpen={showContactModal}
  onRequestClose={() => setShowContactModal(false)}
  contentLabel='Add Contact'
  style={{
    overlay: {
      top: '80px',
      width: '100%',
      background: 'black',
    },
    content: {
      margin: 'auto',
    },
  }}
>
  <div>
  <div  className='ulAssignUser'>
    <h2>Assign User</h2>
    <div className='modaluserlist'>
      {contacts.map((user) => (
        <div className='modaluserind' key={user._id}>
          {user.name}
          <FontAwesomeIcon className='plusiconmodal' icon={faPlus} onClick={() => handleAssociateContact(user._id)} />
        </div>
      ))}
    </div>
    <button className='buttonModalAssignUser' onClick={() => setShowContactModal(false)}>Close</button>
    </div>
  </div>
</Modal>




      </div>
      <div className='contactIconBoxMain'>
      {title === "Admin" && (
      <div className='contactIconBox'>
      <div className='contactMaterialBox'>
      <div className='noContactButton'>${contact.debt}</div>
      </div>
      
      <div className='contactMaterialBox'>
  {isEditingCatalogue ? (
    <div>
      <input
        type="text"
        value={editedCatalogue}
        onChange={(e) => setEditedCatalogue(e.target.value)}
      />
      <button onClick={handleSaveCatalogue}>Save</button>
    </div>
  ) : (
    <>
      {contact.catalogue}{' '}
      <FontAwesomeIcon
        className='editIconCatalogue'
        icon={faCalculator}
        onClick={() => setIsEditingCatalogue(true)}
      />
    </>
  )}
</div>

      <div className='contactMaterialBox'>
      <div className='noContactButton'><FontAwesomeIcon className='iconMaterial' icon={faComputer} />{contact.weee?.kilogram} Kg ({contact.weee?.purchases})</div>
    </div>
    <div className='contactMaterialBox'>
  <div className='noContactButton'>
    <FontAwesomeIcon className='iconMaterial' icon={faCar} />
    {contact.ceramics?.kilogram.toFixed(2)} Kg ({contact.ceramics?.purchases})
    {contact.ceramics?.kilogram > 0 &&
      <span className="marginInfo">
        (${(contact.ceramics?.indCeramicsPurchases.reduce((sum, purchase) => sum + (purchase.usdValue || 0), 0) / contact.ceramics?.kilogram).toFixed(2)})
      </span>
    }
  </div>
</div>


        <div className='contactMaterialBox'>
          <FontAwesomeIcon className='plusicon' icon={faPlus} onClick={handleAddContact} />
        </div>
        <div className='contactMaterialBox'>
      <div className='noContactButton'>{contact.noAnswerCount}</div>
    </div>
    <div className='contactMaterialBox'>
  <Link to="/customer-material-line-chart" state={{  weeeData: contact.weee?.indWeeePurchases , ceramicsData: contact.ceramics?.indCeramicsPurchases }}>
    <FontAwesomeIcon className='iconChart' icon={faChartLine} />
  </Link>
</div>

        </div>
)}





        <div className='contactIconBox'>
        <div className='contactMaterialBox'>
      <Link to="/contact-list">
          <button className='noContactButton' onClick={handleNoAnswer}>No Answer</button>
          </Link>
        </div>
        <div className='contactMaterialBox'>

          {isEditingMaterialType ? (
            <div>
              <select
                value={editedMaterialType}
                onChange={(e) => setEditedMaterialType(e.target.value)}
              >
                <option value="Cats">Cats</option>
                <option value="Weee">Weee</option>
                <option value="Both">Both</option>
              </select>
              <button onClick={handleSaveMaterialType}>Save</button>
            </div>
          ) : (
            <>
              {renderMaterialIcon(contact.materialType)}
              <FontAwesomeIcon
                className='editIconMaterial'
                icon={faEdit}
                onClick={() => setIsEditingMaterialType(true)}
              />
            </>
          )}
        </div>
        <div className='contactMaterialBox'>
          <div className='noContactButton'>{contact.materialType}</div>
        </div>
        <div className='contactMaterialBox' style={{ backgroundColor: contact.user ? 'lightgreen' : 'red' }}>
      <strong>{contact.user ? '' : ''}</strong>
      {contact.user ? (
    <div className="noteContent">{contact.user.name}</div>
     ) : (
        <div className="noteContent">Not Assigned</div>
        )}
      </div>
              <div className='contactMaterialBox'>
          <strong>Last:</strong>{' '}
          {contact.lastContact ? new Date(contact.lastContact).toLocaleDateString() : 'N/A'}
        </div>
       </div>
          </div>



          {contact && (
            <div className='contactIconBox'>
  <div className='contactMaterialBox'>
    <strong>Last Purchase:</strong>{' '}
    {ceramicsData && ceramicsData.length > 0 ?
      new Date(ceramicsData[ceramicsData.length - 1].date).toLocaleDateString() :
      'N/A'}
  </div>
  <div className='contactMaterialBox'>
    <strong>Next Purchase:</strong>{' '}
    {ceramicsData && ceramicsData.length > 0 ?
      predictNextPurchaseDate(
        ceramicsData[ceramicsData.length - 1].date, // Use the date of the last purchase
        calculateFrequency(ceramicsData) // Calculate the average frequency dynamically
      ) :
      'N/A'}
  </div>
  <div className='contactMaterialBox'>
    <strong>Kg Expected:</strong>{' '}
    {ceramicsData && ceramicsData.length > 0 ?
      calculateExpectedKg(ceramicsData).toFixed(2) :
      'N/A'}
  </div>
</div>
      )}




      {contact.notes && Array.isArray(contact.notes) && (
        <div className='contactDetailsNotesMain'>
          <div className='contactDetailsNotesBox'>
            <strong>Notes:</strong>
            {contact.notes && Array.isArray(contact.notes) && contact.notes.map((note, index) => (
              <div key={index} className={index !== contact.notes.length - 1 ? 'noteSeparator' : ''}>
                <div className="noteContent">{new Date(note.createdAt).toLocaleString()} - {note.content}</div>
              </div>
            ))}
          </div>
          <div className='addNote'>
            <textarea
              rows='4'
              cols='50'
              placeholder='Add a note...'
              value={newNote}
              onChange={(e) => setNewNote(e.target.value)}
            />


{userPosition !== "Sales" && (
  <div className='weightEntryBox'>
    <div className='weightEntry'>
      Weee Kg
      <input
        className='weightInput'
        type="number"
        value={weeeKgInput}
        onChange={(e) => setWeeeKgInput(e.target.value)}
      />
      USD Profit
      <input
        className='weightInput'
        type="number"
        value={weeeUsdValueInput}
        onChange={(e) => setWeeeUsdValueInput(e.target.value)}
      />
    </div>
    <div className='weightEntry'>
      Ceramics Kg
      <input
        className='weightInput'
        type="number"
        value={ceramicsKgInput}
        onChange={(e) => setCeramicsKgInput(e.target.value)}
      />
      USD Profit
      <input
        className='weightInput'
        type="number"
        value={ceramicsUsdValueInput}
        onChange={(e) => setCeramicsUsdValueInput(e.target.value)}
      />
    </div>
    <div className='weightEntry'>
      Borrowed in $
      <input
        className='weightInput'
        type="number"
        value={borrowedInput}
        onChange={(e) => setBorrowedInput(e.target.value)}
      />
      Returned in $
      <input
        className='weightInput'
        type="number"
        value={returnedInput}
        onChange={(e) => setReturnedInput(e.target.value)}
      />
    </div>
  </div>
)}


          


<button onClick={handleSaveNote} disabled={!newNote.trim() || (ceramicsKgInput.trim() && !ceramicsUsdValueInput.trim()) || (!ceramicsKgInput.trim() && ceramicsUsdValueInput.trim()) }>Save</button>
          </div>
       
        </div>
      )}
    </div>
  );
  
};

export default ContactDetails;
