import React, { useEffect, useState } from 'react';
import './prmetals.css';

function ChargesInfo() {
  const [charges, setCharges] = useState(null);
  const isLocal = window.location.hostname === 'localhost';
  const baseUrl = isLocal ? 'http://localhost:3003' : 'https://crg-1-pakistan-bf9fced80b73.herokuapp.com';

  useEffect(() => {
    fetchCharges();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchCharges = () => {
    fetch(`${baseUrl}/api/charges`)
      .then(response => response.json())
      .then(data => {
        setCharges(data[0]); // Assuming there's only one charges document
      })
      .catch(error => {
        console.error('Error fetching charges:', error);
      });
  };

  if (!charges) {
    return <div>Loading charges information...</div>;
  }

  return (
    <div>
    <div className='pageheader'>Charges</div>
    <div className='prmetalsmain'>
      <div className='simpletext'>Export Charge: ${charges.exportCharge}</div>
      <div className='simpletext'>Processing Charge: {charges.processingCharge} %</div> 
      <div className='simpletext'>Discount Charge: {charges.discountCharge} %</div> 
      <div className='simpletext'>Exchange Rate: {charges.exchangeRate}</div> 
      </div>
    </div>
  );
}

export default ChargesInfo;
