// ContactStats.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../userAuth'; 
import { useNavigate } from 'react-router-dom';
import './contactstats.css';

const ContactStats = () => {
  const { isLoggedIn } = useAuth();
  const [contacts, setContacts] = useState([]);
  const [activeFilter, setActiveFilter] = useState('total'); 
  const title = localStorage.title;
  const navigate = useNavigate();
  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login');
    }
  }, [isLoggedIn, navigate]);
  const [searchQuery, setSearchQuery] = useState('');
  const isLocal = window.location.hostname === 'localhost';
  const baseUrl = isLocal ? 'http://localhost:3001' : 'https://crg-1-pakistan-bf9fced80b73.herokuapp.com';
  

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/contacts`);
        setContacts(response.data);
      } catch (error) {
        console.error('Error fetching contacts:', error);
      }
    };

    fetchContacts();
  }, [baseUrl]);

  const calculateMarginPerKilogram = (indCeramicsPurchases, totalKilogram) => {
    const totalUsdValue = calculateCatsUsdValue(indCeramicsPurchases);
    return totalUsdValue / totalKilogram;
  };

  const calculateCatsUsdValue = (indCeramicsPurchases) => {
    return indCeramicsPurchases.reduce((sum, purchase) => sum + purchase.usdValue, 0);
  };

  const calculateWeeeUsdValue = (indWeeePurchases) => {
    return indWeeePurchases.reduce((sum, purchase) => sum + purchase.usdValue, 0);
  };

  const isContactOver30DaysOld = (contact) => {
    const latestPurchaseDate = contact.ceramics.indCeramicsPurchases.length > 0
      ? new Date(Math.max(...contact.ceramics.indCeramicsPurchases.map((purchase) => new Date(purchase.date).getTime())))
      : null;

    return latestPurchaseDate && isOver30DaysOld(latestPurchaseDate);
  };

  const isNewCats = (contact) => {
    return (contact.materialType === 'Cats' || contact.materialType === 'Both') &&
           contact.ceramics.indCeramicsPurchases.length === 0;
  };

  const isNewWeee = (contact) => {
    return (contact.materialType === 'Weee' || contact.materialType === 'Both') &&
           contact.weee.indWeeePurchases.length === 0;
  };

  const countAssignedUsers = () => {
    return contacts.filter(contact => contact.user && contact.user.id).length;
  };
  
  const countNotAssignedUsers = () => {
    return contacts.filter(contact => !contact.user || !contact.user.id).length;
  };

  const countInactiveContacts = () => {
    return contacts.filter(contact => isContactOver30DaysOld(contact)).length;
  };

  const handleFilterClick = (filter) => {
    setActiveFilter(filter);
  };

  const renderContactStatsHeader = (label, filter) => (
    <div
      className={`contactStatsHeader ${activeFilter === filter ? 'active' : ''}`}
      onClick={() => handleFilterClick(filter)}
    >
      {label}
      {filter === 'total' && <span className="contactCount">{contacts.length}</span>}
      {filter === 'notAssigned' && <span className="contactCount">{countNotAssignedUsers()}</span>}
      {filter === 'notActive' && <span className="contactCount">{countInactiveContacts()}</span>}
      {filter === 'newWeee' && <span className="contactCount">{contacts.filter(isNewWeee).length}</span>}
      {filter === 'newCats' && <span className="contactCount">{contacts.filter(isNewCats).length}</span>}
      {filter === 'zeroKilogram' && <span className="contactCount">{contacts.filter(contact => contact.ceramics.kilogram === 0).length}</span>}
      {filter === 'moreKilogram' && <span className="contactCount">{contacts.filter(contact => contact.ceramics.kilogram > 0).length}</span>}
      {filter === 'warnings' && <span className="contactCount">{contacts.filter(contact => contact.warnings > 4).length}</span>}
    </div>
  );
  

  const renderFilteredContacts = () => {
    const filteredContacts = contacts.filter(contact =>
      (searchQuery === '' || contact.name.toLowerCase().includes(searchQuery.toLowerCase())) &&
      ((activeFilter === 'notAssigned' && (!contact.user || !contact.user.id)) ||
        (activeFilter === 'notActive' && isContactOver30DaysOld(contact)) ||
        (activeFilter === 'newWeee' && isNewWeee(contact)) ||
        (activeFilter === 'newCats' && isNewCats(contact)) ||
        (activeFilter === 'total') ||
        (activeFilter === 'zeroKilogram' && contact.ceramics.kilogram === 0) ||
        (activeFilter === 'warnings' && contact.noAnswerCount > 4) ||
        (activeFilter === 'moreKilogram' && contact.ceramics.kilogram > 0))
    );
  
    return filteredContacts;
  };
  
  

  const handleContactClick = (contactId) => {
    // Navigate to the contact-edit-admin component with the contactId
    navigate(`/contact-edit-admin/${contactId}`);
  };


  const handleExportPDF = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/export-pdf`, {
        responseType: 'blob', // Ensure response is treated as binary data
      });
  
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'contacts.pdf');
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Error exporting contacts to PDF:', error);
    }
  };

  const handleExportExcel = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/export-excel`, {
        responseType: 'blob', // Ensure response is treated as binary data
      });
  
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'all-contacts.xlsx');
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Error exporting contacts to excel:', error);
    }
  };

  const handleExportLatePDF = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/export-late-pdf`, {
        responseType: 'blob', // Ensure response is treated as binary data
      });
  
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'late-contacts.pdf');
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Error exporting late contacts to PDF:', error);
    }
  };

  const handleExportLateExcel = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/export-late-excel`, {
        responseType: 'blob', // Ensure response is treated as binary data
      });
  
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'late-contacts.xlsx'); // Set download attribute to '.xlsx' extension
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Error exporting late contacts to Excel:', error);
    }
  };
  

  const handleExportNotActivePDF = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/export-not-active-pdf`, {
        responseType: 'blob', // Ensure response is treated as binary data
      });
  
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'not-active-contacts.pdf');
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Error exporting not active contacts to PDF:', error);
    }
  };

  const handleExportNotActiveExcel = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/export-not-active-excel`, {
        responseType: 'blob', // Ensure response is treated as binary data
      });
  
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'not-active-contacts.xlsx');
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Error exporting not active contacts to PDF:', error);
    }
  };
  
  
  


  return (
    <div className='contactStatsMain'>
        {isLoggedIn && title === 'Admin' && (
        <>
      <h2>Customer Stats</h2>
      <div className="contactStatsThird">
      <div className="contactStatsThirdColumn">
      <div className='exportButton' onClick={handleExportPDF}>All PDF</div>
      <div className='exportButton' onClick={handleExportExcel}>All Excel</div>
      </div>
      <div className="contactStatsThirdColumn">
      <div className='exportButton'  onClick={handleExportLatePDF}>Late PDF</div>
      <div className='exportButton'  onClick={handleExportLateExcel}>Late Excel</div>
      </div>
      <div className="contactStatsThirdColumn">
      <div className='exportButton'  onClick={handleExportNotActivePDF}>Inactive PDF</div>
      <div className='exportButton'  onClick={handleExportNotActiveExcel}>Inactive Excel</div>
      </div>
      </div>
      <div className="contactStatsThird">
  <div className="contactStatsFilter">{renderContactStatsHeader('Total' , 'total')}</div>
  <div className="contactStatsFilter">{renderContactStatsHeader('Not Assigned', 'notAssigned')}</div>
  <div className="contactStatsFilter">{renderContactStatsHeader('Not Active', 'notActive')}</div>
  <div className="contactStatsFilter">{renderContactStatsHeader('Weee New', 'newWeee')}</div>
  <div className="contactStatsFilter">{renderContactStatsHeader('Cats New', 'newCats')}</div>
  <div className="contactStatsFilter">{renderContactStatsHeader('No Buys', 'zeroKilogram')}</div>
  <div className="contactStatsFilter">{renderContactStatsHeader('Active', 'moreKilogram')}</div>
  <div className="contactStatsFilter">{renderContactStatsHeader('Warnings', 'warnings')}</div>
</div>

      <div className="contactStatsThird">
      <input
              className='customerSearch'
              type="text"
              placeholder="Search by name"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              />
               </div>
      <div className="contactStatsSecond">
      {renderFilteredContacts().map((contact) => (
         <div key={contact._id} className={isContactOver30DaysOld(contact) ? 'over30Days' : ''}
         onClick={() => handleContactClick(contact._id)}
         >
            <div className='contactStatsName'>Name: {contact.name}</div>
            <div className='contactStatsNewBox'>
            {isNewCats(contact) && <div className='contactStatsNew'>CATS NEW</div>}
            {isNewWeee(contact) && <div className='contactStatsNew'>WEEE NEW</div>}
            </div>
            <div>Phone: {contact.number}</div>
            <div>First Entry: {contact.notes.length > 0 ? new Date(contact.notes[0].createdAt).toLocaleDateString() : 'N/A'}</div>
            {contact.materialType === 'Cats' || contact.materialType === 'Both' ? (
              <>
                <div>
                  Cats last purchase:{' '}
                  {contact.ceramics.indCeramicsPurchases.length > 0
                    ? new Date(
                        Math.max(
                          ...contact.ceramics.indCeramicsPurchases.map(
                            (purchase) => new Date(purchase.date).getTime()
                          )
                        )
                      ).toLocaleDateString()
                    : 'N/A'}
                </div>

                Warnings: {contact.noAnswerCount}

                <div>
                  Cats USD: $ {' '}
                  {contact.ceramics.indCeramicsPurchases.length > 0
                    ? calculateCatsUsdValue(contact.ceramics.indCeramicsPurchases)
                    : 'N/A'}
                </div>
                <div>
                  Cats Kg: {contact.ceramics.kilogram}
                </div>
                <div>
                  Cats Margin: $ {calculateMarginPerKilogram(contact.ceramics.indCeramicsPurchases, contact.ceramics.kilogram).toFixed(2)}
                </div>
                <div>
                  {contact.ceramics.indCeramicsPurchases.length > 0 &&
                    isOver30DaysOld(contact.ceramics.indCeramicsPurchases[0].date) && (
                      <div style={{ color: 'red' }}>ALERT CATS: Last Purchase is over 30 days old</div>
                    )}
                </div>
              </>
            ) : null}
            {contact.materialType === 'Weee' || contact.materialType === 'Both' ? (
              <>
                <div>
                  Weee last purchase:{' '}
                  {contact.weee.indWeeePurchases.length > 0
                    ? new Date(
                        Math.max(
                          ...contact.weee.indWeeePurchases.map(
                            (purchase) => new Date(purchase.date).getTime()
                          )
                        )
                      ).toLocaleDateString()
                    : 'N/A'}
                </div>
                <div>
                  Weee USD:{' '}
                  {contact.weee.indWeeePurchases.length > 0
                    ? calculateWeeeUsdValue(contact.weee.indWeeePurchases)
                    : 'N/A'}
                </div>
                <div>
                  Weee Kg: {contact.weee.kilogram}
                </div>
                <div>
                  {contact.weee.indWeeePurchases.length > 0 &&
                    isOver30DaysOld(contact.weee.indWeeePurchases[0].date) && (
                      <div style={{ color: 'red' }}>ALERT WEEE: Last Purchase is over 30 days old</div>
                    )}
                </div>
              </>
            ) : null}
          </div>
            ))}
            </div>
          </>
        )}
      </div>
    );
  };

// Function to check if a date is over 30 days old
const isOver30DaysOld = (dateString) => {
  const currentDate = new Date();
  const purchaseDate = new Date(dateString);
  const timeDifference = currentDate.getTime() - purchaseDate.getTime();
  const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

  return daysDifference > 30;
};


export default ContactStats;
