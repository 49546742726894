import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './saleslogin.css';

const UserLoginForm = () => {
  const [userId, setUserId] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const isLocal = window.location.hostname === 'localhost';
  const baseUrl = isLocal ? 'http://localhost:3001' : 'https://crg-1-pakistan-bf9fced80b73.herokuapp.com';

  const handleInputChange = (e) => {
    setUserId(e.target.value);
    // Clear the error message when the user starts typing
    setErrorMessage('');
  };

  const handleEnterPress = async () => {
    // Check if the ID is empty
    if (!userId.trim()) {
      setErrorMessage('ID field cannot be empty');
      return;
    }

    try {
      const response = await fetch(`${baseUrl}/api/users/${userId}`);
      const userData = await response.json();

      if (userData.position !== 'Sales' && userData.position !== 'Center') {
        setErrorMessage('Please Use Your ID');
        return;
      }

      if (response.ok) {
        const userInfo = {
          id: userData._id,
          name: userData.name,
          position: userData.position,
        };

        navigate('/contact-list', { state: { userInfo } });
      } else {
        setErrorMessage('ID not found in the database');
      }
    } catch (error) {
      console.error('Error checking user:', error);
      setErrorMessage('Error checking user');
    }
  };

  return (
    <div className='salesLoginMain'>
      <div className='salesLoginSecond'>
        <label>
          Enter Your ID:
          <input type="text" value={userId} onChange={handleInputChange} />
        </label>
        <button onClick={handleEnterPress}>Enter</button>
        {errorMessage && <p>{errorMessage}</p>}
      </div>
    </div>
  );
};

export default UserLoginForm;

