import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import WeeMainComponent from './weemain/weemain';
import HomeMain from './home/homemain'
import AdminSingle from './admin/adminsingle';
import ImageUploader from './admin/images';
import AdminMain from './admin/adminmain';
import './App.css';
import Header from './header';
import Cart from './cart/cart';
import axios from 'axios';
import UserSignupLogin from './user/userlogin';
import CartCustom from './cart/cartnoprices';
import CartPopup from './cartpopup';
import ContactsMain from './database/contactsMain';
import ContactList from './database/contactList';
import ContactFormWrapper from './database/ContactFormWrapper';
import ContactDetails from './database/contactDetails';
import AddUserWrapper from './database/AddUserWrapper';
import PriceCalculator from './ppmcalc.js/ppmcalc';
import CalendarView from './database/calendarCustomer';
import CustomerMaterialLineChart from './charts/CustomerMaterialLineChart';
import UserLoginForm from './database/saleslogin';
import UserStatistics from './database/UserStats';
import ContactStats from './database/contactstats';
import ContactEditAdmin from './database/contacteditadmin';
import CrgEgyptMain from './crgegypt/crgegyptmain';
import EditFixedExpenses from './crgegypt/editfixedexpenses';
import EditVariableExpenses from './crgegypt/editvariableexpenses';
import DebtBreakdown from './crgegypt/debtbreakdown';
import UserDailyMaterialChart from './charts/userStatsChart';
import CalendarWarehouse from './database/calendarWarehouse';
import { AuthProvider } from './userAuth'; 
import UserAdminDailyMaterialChart from './charts/userStatsChartAdmin';
import CalendarPredictView from './database/calendarPredict';

const isLocal = window.location.hostname === 'localhost';
const baseUrl = isLocal ? 'http://localhost:3003' : 'https://crg-1-pakistan-bf9fced80b73.herokuapp.com';

axios.defaults.baseURL = baseUrl;

function App() {
  const [title, setTitle] = useState('');

  const appStyle = {
    backgroundImage: `url('/BG.png')`,  // Reference the image directly
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    minHeight: '100vh',
  };

  const handleContactAdded = (newContact) => {
    console.log('New contact added:', newContact);
  };
  

  return (
    <AuthProvider>
    <Router>
      <div className="App" style={appStyle}>
      <div className="App">
        <Header title={title} setTitle={setTitle} />
        <Routes>
          <Route
            path="/"
            element={<HomeMain title={title}/>}
          />
          <Route
            path="/weee"
            element={<WeeMainComponent title={title}/>}
          />
          <Route
            path="/admin/:documentId/*"
            element={<AdminSingle title={title}/>}
          />
          <Route
            path="/admin/:documentId/upload"
            element={<ImageUploader title={title}/>}
          />
          <Route
            path="/admin"
            element={<AdminMain title={title} />}
          />
          <Route
            path="/cart/:cartId"
            element={<Cart title={title} />}
          />
          <Route
            path="/cartcustom/:cartId"
            element={<CartCustom title={title} />}
          />
          <Route
            path="/login"
            element={<UserSignupLogin setTitle={setTitle} />}
          />
           <Route
            path="/cartaccess"
            element={<CartPopup title={title}  />}
          />
            <Route
            path="/customers"
            element={<ContactsMain title={title}/>}
          />
        <Route path="/contact-list" element={<ContactList title={title}/>}/>
        <Route path="/contact-details/:id" element={<ContactDetails />} />
        <Route
            path="/contact-form"
           element={<ContactFormWrapper onContactAdded={handleContactAdded} />}
          />
        <Route
           path="/adduser-form"
           element={<AddUserWrapper onUserAdded={handleContactAdded} />}
        />
         <Route
            path="/price"
            element={<PriceCalculator title={title} />}
          />
         <Route
           path="/calendar-view"
           element={<CalendarView title={title}/>}
        />
        <Route
           path="/calendar-predict-view"
           element={<CalendarPredictView title={title}/>}
        />
        <Route
           path="/customer-material-line-chart"
           element={<CustomerMaterialLineChart/>}
        />
       <Route
           path="/sales-login"
           element={<UserLoginForm title={title}/>}
        />
          <Route path="/user-statistics/:id" 
          element={<UserStatistics />} />
            <Route path="/contact-stats" element={<ContactStats />} />
        <Route path="/contact-edit-admin/:contactId" element={<ContactEditAdmin />} />

        <Route path="/crg-egypt-data" element={<CrgEgyptMain />} />

        <Route path="/edit-fixed-expenses" element={<EditFixedExpenses />} />
        <Route path="/edit-variable-expenses" element={<EditVariableExpenses />} />
        <Route path="/debt-breakdown" element={<DebtBreakdown />} />
        <Route path="/user-report" element={<UserDailyMaterialChart />} />
        <Route path="/user-report-admin" element={<UserAdminDailyMaterialChart />} />
        <Route path="/warehouse-schedule" element={<CalendarWarehouse />} />
        </Routes>
      </div>
      </div>
    </Router>
    </AuthProvider>
  );
}

export default App;
