import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../userAuth'; 
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import './contacts.css';

const ContactsMain = () => {
  const { isLoggedIn } = useAuth();
  const [users, setUsers] = useState([]);
  const title = localStorage.title;
  const navigate = useNavigate();
  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login');
    }
  }, [isLoggedIn, navigate]);
  const isLocal = window.location.hostname === 'localhost';
  const baseUrl = isLocal ? 'http://localhost:3001' : 'https://crg-1-pakistan-bf9fced80b73.herokuapp.com';

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/users`);
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, [baseUrl]);

 // const handleDeleteUser = async (userId) => {
 //   try {
 //    await axios.delete(`${baseUrl}/api/users/${userId}`);
 //     setUsers((prevUsers) => prevUsers.filter((user) => user._id !== userId));
 //     console.log(`User ${userId} deleted successfully`);
 //   } catch (error) {
 //     console.error('Error deleting user:', error);
 //   }
 // };

  const handleUserStatisticsClick = (userId) => {
    // Navigate to the UserStatistics component with the corresponding user's ID
    navigate(`/user-statistics/${userId}`);
  };

  return (
    <div className='contactsSecond'>
            {isLoggedIn && title === 'Admin' && (
      <div className='contactsMain'>
        <h2>CRG Pakistan Management</h2>
        <div className='customersMain'>
          <Link to="/contact-list">
            <button className='contactButton'>Customer List</button>
          </Link>
          <Link to="/contact-form">
            <button className='contactButton'>Add Customer</button>
          </Link>
          <Link to="/adduser-form">
            <button className='contactButton'>Add User</button>
          </Link>
          <Link to="/calendar-view">
            <button className='contactButton'>View Calendar</button>
          </Link>
          </div>
          <div className='customersMain'>
          <Link to="/contact-stats">
    <button className='contactButton'>Customer Stats</button>
  </Link>
  <Link to="/crg-egypt-data">
    <button className='contactButton'>CRG Pakistan</button>
  </Link>
        </div>
        

        <div className='userMainList'>
        <h3>All Users</h3>
          <div className="table">
            <div className="table-row header">
              <div className="table-cell">Name</div>
              <div className="table-cell">Position</div>
              <div className="table-cell">Lost Customers</div>
              <div className="table-cell">Contact Count</div>
              <div className="table-cell">Actions</div>
              <div className="table-cell">User Statistics</div> {/* New column header */}
            </div>
            {users.map((user) => (
              <div className="table-row" key={user._id}>
                <div className="table-cell">{user.name}</div>
                <div className="table-cell">{user.position}</div>
                <div className={`table-cell ${user.lostCustomersCount === 0 ? '' : (user.lostCustomersCount <= 5 ? 'yellowBackground' : 'redBackground')}`}>{user.lostCustomersCount}</div>
                <div className="table-cell">{user.contactsCount}</div>
                <div className="table-cell">
                  <FontAwesomeIcon
                    icon={faTrashCan} 
                    className="trashicon"
                   // onClick={() => handleDeleteUser(user._id)}
                  />
                </div>
                <div className="table-cell">
                  <button className='viewuseradmin' onClick={() => handleUserStatisticsClick(user._id)}>
                    View
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
            )}
      </div>
  );
};

export default ContactsMain;
