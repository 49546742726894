import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../userAuth'; 
import { useNavigate } from 'react-router-dom';
import './contactForm.css';

const AddUserForm = ({ onUserAdded }) => {
  const { isLoggedIn } = useAuth();
  const isLocal = window.location.hostname === 'localhost';
  const baseUrl = isLocal ? 'http://localhost:3001' : 'https://crg-1-pakistan-bf9fced80b73.herokuapp.com';
  const navigate = useNavigate();
  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login');
    }
  }, [isLoggedIn, navigate]);

  const [newContact, setNewContact] = useState({
    name: '',
    number: '',
    address: '',
    lastContact: '',
    nextContact: '',
    position: 'Sales',
    notes: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewContact((prevContact) => ({ ...prevContact, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${baseUrl}/api/users`, newContact);
      onUserAdded(response.data);

      // Use navigate to go to "/customers" upon successful user creation
      navigate('/customers');

      setNewContact({
        name: '',
        number: '',
        password: '',
        position: '',
      });
    } catch (error) {
      console.error('Error adding user:', error);
    }
  };

  return (
    <div className='contactFormMain'>
            {isLoggedIn && (
      <div className='contactFormSecond'>
        <div className='contactFormThird'>
          <form onSubmit={handleSubmit}>
            <label className='label'>Name:</label>
            <input type="text" name="name" value={newContact.name} onChange={handleChange} required />

            <label>Number:</label>
            <input type="text" name="number" value={newContact.number} onChange={handleChange} />

            <label>Password:</label>
            <input type="password" name="password" value={newContact.password} onChange={handleChange} required />

            <label className='label'>Position:</label>
            <select name="position" value={newContact.position} onChange={handleChange}>
              <option value="Sales">Sales</option>
              <option value="Manager">Manager</option>
              <option value="Center">Center</option>
            </select>

            <button className='bottombutton' type="submit">Add User</button>
          </form>
        </div>
      </div>
            )}
    </div>
  );
};

export default AddUserForm;
