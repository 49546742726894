// HomeMain.js

import React, { useState } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './homemain.css';
import CustomerService from './customerservice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment } from '@fortawesome/free-solid-svg-icons';
import Products from './products';
import Info from './info';
import Footer from './footer';

function HomeMain() {
  const [isCustomerServiceOpen, setCustomerServiceOpen] = useState(false);

  const handleToggleCustomerService = () => {
    setCustomerServiceOpen(!isCustomerServiceOpen);
  };

  return (
    <div className='weemainhome'>
      <div className="weesecondhome">
        <div className="card-containerhome">
          <Info />
          <Products />
        </div>
      </div>

      {/* Add the Footer component */}
      <Footer />
    </div>
  );
}

export default HomeMain;
