               

import React, { useEffect, useState } from 'react';
import ChargesInfo from './charges';
import { useNavigate } from 'react-router-dom';
import PreciousMetalsPrices from './preciousMetalsPrices';
import Popup from './popup';
import './admin.css';
import { useAuth } from '../userAuth'; 


function AdminMain() {
  const [documents, setDocuments] = useState([]);
  const [charges, setCharges] = useState([]);
  const navigate = useNavigate(); 
  const { isLoggedIn, } = useAuth();
  const [updatedFields, setUpdatedFields] = useState({});
  const title = localStorage.title;
  const [exportCharge, setExportCharge] = useState(0);
  const [processingCharge, setProcessingCharge] = useState(0);
  const [discountCharge, setDiscountCharge] = useState(0);
  const [exchangeRate, setExchangeRate] = useState(0);
  const [goldPrice, setGoldPrice] = useState(0);
  const [silverPrice, setSilverPrice] = useState(0);
  const [palladiumPrice, setPalladiumPrice] = useState(0);
  const [copperPrice, setCopperPrice] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const isLocal = window.location.hostname === 'localhost';
  const baseUrl = isLocal ? 'http://localhost:3003' : 'https://crg-1-pakistan-bf9fced80b73.herokuapp.com';

  useEffect(() => {
    // Redirect if user is not logged in or not a Developer
    if (title !== 'Admin') {
        navigate('/'); 
    }
}, [isLoggedIn, navigate, title]);


  const handleResetPrices = async () => {
    try {
      setIsLoading(true);
      setShowPopup(true);

      const updatedDocuments = documents.map(document => ({
        ...document,
        price: document.resetPrice,
        profit: document.priceHundred - document.price
      }));

      await Promise.all(
        updatedDocuments.map(updatedDocument =>
          fetch(`${baseUrl}/api/documents/${updatedDocument._id}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedDocument),
          })
        )
      );

      console.log('Prices reset successfully');
      setUpdatedFields({}); // Reset the updatedFields state
      fetchDocuments();
      setIsLoading(false);
      setShowPopup(false);
      setRefresh(true);
    } catch (error) {
      console.error('Error resetting prices:', error);
    }
  };


  const handlePopupClose = () => {
    setShowPopup(false);
    setIsLoading(false);
    setRefresh(true);
  };

  useEffect(() => {
    if (!showPopup && !isLoading) {
      setRefresh(true); // Set the refresh flag to true after the popup is closed and loading is complete
    }
  }, [showPopup, isLoading]);

  useEffect(() => {
    if (refresh) {
      setRefresh(false);
  
      // Perform any additional logic or data fetching if needed
  
      fetchDocuments();
      fetchCharges();
  
      setTimeout(() => {
        setRefresh(true);
      }, 10000); // 10000 milliseconds = 10 seconds
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);
  
  useEffect(() => {
    fetchDocuments();
    fetchCharges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  

  const fetchDocuments = () => {
    fetch(`${baseUrl}/api/documents`)
      .then(response => response.json())
      .then(data => {
        const updatedDocuments = data.map(document => ({
          ...document,
          profit: document.priceHundred - document.price
        }));        
        const sortedDocuments = updatedDocuments.sort((a, b) => a.weeName.localeCompare(b.weeName));
        setDocuments(sortedDocuments);
      })
      .catch(error => {
        console.error('Error fetching documents:', error);
      });
  };
  
  

  const fetchCharges = () => {
    fetch(`${baseUrl}/api/charges`)
      .then(response => response.json())
      .then(data => {
        setCharges(data);
      })
      .catch(error => {
        console.error('Error fetching charges:', error);
      });
  };

  const handleEdit = (id, field, value, type) => {
    setUpdatedFields(prevFields => ({
      ...prevFields,
      [id]: {
        ...prevFields[id],
        [field]: value,
        profit: value.priceHundred - value.price
      }
    }));    
  };

  useEffect(() => {
    // Calculate the average price based on the saved values in the database
    const calculateAverage = (documentId) => {
      const document = documents.find(doc => doc._id === documentId);
  
      const compPriceOne = document?.compPriceOne || 0;
      const compPriceTwo = document?.compPriceTwo || 0;
      const compPriceThree = document?.compPriceThree || 0;
  
      // Filter out the 0 values from the calculation
      const prices = [compPriceOne, compPriceTwo, compPriceThree].filter(price => price > 0);
  
      if (prices.length > 0) {
        const sum = prices.reduce((total, price) => total + price, 0);
        const average = sum / prices.length;
  
        // Update the compPriceAverage field with the calculated average
        setUpdatedFields(prevState => ({
          ...prevState,
          [documentId]: {
            ...prevState[documentId],
            compPriceAverage: average
          }
        }));
      }
    };
  
    // Iterate over each document and calculate the average for each
    documents.forEach(document => {
      calculateAverage(document._id);
    });
  }, [documents]);
  

  const handleSaveChanges = async () => {
    try {
      const documentIds = Object.keys(updatedFields);
      setIsLoading(true);
      setShowPopup(true);
  
      const updatedDocuments = documentIds.map(documentId => {
        const updatedDocument = {
          ...updatedFields[documentId],
          profit: updatedFields[documentId].priceHundred - updatedFields[documentId].price
        };
  
        return fetch(`${baseUrl}/api/documents/${documentId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(updatedDocument),
        });
      });
  
      await Promise.all(updatedDocuments);
  
      console.log('Documents updated successfully');
      setUpdatedFields({}); // Reset the updatedFields state
      fetchDocuments();
      setIsLoading(false);
      setShowPopup(false);
  
      setRefresh(true); 
    } catch (error) {
      console.error('Error updating documents:', error);
    }
  };
  

  const handleExportChargeChange = event => {
    setExportCharge(Number(event.target.value));
  };

  const handleProcessingChargeChange = event => {
    setProcessingCharge(Number(event.target.value));
  };

  const handleDiscountChargeChange = event => {
    setDiscountCharge(Number(event.target.value));
  };

  const handleExchangeCharge = event => {
    setExchangeRate(Number(event.target.value));
  };

  const handleGoldPrice = event => {
    setGoldPrice(Number(event.target.value));
  };

  
  const handleSilverPrice = event => {
    setSilverPrice(Number(event.target.value));
  };

  const handlePalladiumPrice = event => {
    setPalladiumPrice(Number(event.target.value));
  };

  const handleCopperPrice = event => {
    setCopperPrice(Number(event.target.value));
  };

  const handleApplyExportCharge = async () => {
    if (charges.length === 0) {
      console.error('Charges array is empty');
      return;
    }
  
    try {
      const updatedExportCharge = exportCharge;
      setIsLoading(true)
      setShowPopup(true);
  
      // Update weemain documents
      const updatedDocuments = documents.map(document => {
        const updatedPrice = document.price - updatedExportCharge;
        const updatedProfit = document.priceHundred - updatedPrice;
        return {
          ...document,
          price: updatedPrice,
          profit: updatedProfit,
        };
      });
  
      await Promise.all(
        updatedDocuments.map(updatedDocument =>
          fetch(`${baseUrl}/api/documents/${updatedDocument._id}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedDocument),
          })
        )
      );
  
      // Update charges document
      const chargesId = charges[0]._id;
      await fetch(`${baseUrl}/api/charges/${chargesId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ exportCharge: updatedExportCharge }),
      });
  
      console.log('Documents and charges updated successfully');
      setUpdatedFields({}); // Reset the updatedFields state
      fetchDocuments();
      setIsLoading(false);
      setShowPopup(false);

      setRefresh(true);
    } catch (error) {
      console.error('Error updating documents and charges:', error);
    }
  };
  

  const handleApplyProcessingCharge = async () => {
    if (charges.length === 0) {
      console.error('Charges array is empty');
      return;
    }
  
    try {
      const updatedProcessingCharge = processingCharge;
      setIsLoading(true)
      setShowPopup(true);
  
      // Update weemain documents
      const updatedDocuments = documents.map(document => {
        const updatedPrice = document.price * (1 - updatedProcessingCharge / 100);
        const updatedProfit = document.priceHundred - updatedPrice;
        return {
          ...document,
          price: updatedPrice,
          profit: updatedProfit
        };
      });
  
      await Promise.all(
        updatedDocuments.map(updatedDocument =>
          fetch(`${baseUrl}/api/documents/${updatedDocument._id}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedDocument),
          })
        )
      );
  
      // Update charges document
      const chargesId = charges[0]._id;
      await fetch(`${baseUrl}/api/charges/${chargesId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ processingCharge: updatedProcessingCharge }),
      });
  
      console.log('Documents and charges updated successfully');
      setUpdatedFields({}); // Reset the updatedFields state
      fetchDocuments();
      setIsLoading(false);
      setShowPopup(false);

      setRefresh(true);
    } catch (error) {
      console.error('Error updating documents and charges:', error);
    }
  };

  const handleApplyDiscountCharge = async () => {
    if (charges.length === 0) {
      console.error('Charges array is empty');
      return;
    }
  
    try {
      const updatedDiscountCharge = discountCharge;
      setIsLoading(true)
      setShowPopup(true);
  
      // Update weemain documents
      const updatedDocuments = documents.map(document => {
        const updatedPrice = document.price * (1 - updatedDiscountCharge / 100);
        const updatedProfit = document.priceHundred - updatedPrice;
        return {
          ...document,
          price: updatedPrice,
          profit: updatedProfit
        };
      });
  
      await Promise.all(
        updatedDocuments.map(updatedDocument =>
          fetch(`${baseUrl}/api/documents/${updatedDocument._id}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedDocument),
          })
        )
      );
  
      // Update charges document
      const chargesId = charges[0]._id;
      await fetch(`${baseUrl}/api/charges/${chargesId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ discountCharge: updatedDiscountCharge }),
      });
  
      console.log('Documents and charges updated successfully');
      setUpdatedFields({}); // Reset the updatedFields state
      fetchDocuments();
      setIsLoading(false);
      setShowPopup(false);

      setRefresh(true);
    } catch (error) {
      console.error('Error updating documents and charges:', error);
    }
  };


  const handleApplyExchangeRate = async () => {
    if (charges.length === 0) {
      console.error('Charges array is empty');
      return;
    }
  
    try {
      const updatedExchangeRate = exchangeRate;
      setIsLoading(true);
      setShowPopup(true);
  
      // Update WeeMain documents
      const updatedDocuments = documents.map(document => {
        const updatedEgp = document.price * updatedExchangeRate;
        const updatedPriceTenPercentEGP = updatedEgp * 0.9; 
        return {
          ...document,
          egp: updatedEgp,
          priceTenPercentEGP: updatedPriceTenPercentEGP,
        };
      });
  
      await Promise.all(
        updatedDocuments.map(updatedDocument =>
          fetch(`${baseUrl}/api/documents/${updatedDocument._id}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedDocument),
          })
        )
      );
  
      // Update charges document
      const chargesId = charges[0]._id;
      await fetch(`${baseUrl}/api/charges/${chargesId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ exchangeRate: updatedExchangeRate }),
      });
  
      console.log('Documents and charges updated successfully');
      setUpdatedFields({}); // Reset the updatedFields state
      fetchDocuments();
      setIsLoading(false);
      setShowPopup(false);

      setRefresh(true);
    } catch (error) {
      console.error('Error updating documents and charges:', error);
    }
  };
  



  const handleApplyGoldPrice = async () => {
    if (charges.length === 0) {
      console.error('Charges array is empty');
      return;
    }
  
    try {
      const updatedGoldPrice = goldPrice;
      setIsLoading(true);
      setShowPopup(true);
  
      // Update weemain documents
      const updatedDocuments = documents.map(document => {
        const updatedPrice = document.price + updatedGoldPrice;
        const updatedProfit = document.priceHundred - updatedPrice;
        return {
          ...document,
          price: updatedPrice,
          profit: updatedProfit
        };
      });
  
      await Promise.all(
        updatedDocuments.map(updatedDocument =>
          handleEdit(updatedDocument._id, 'price', updatedDocument.price, 'weemain')
        )
      );
  
      // Update charges document
      const chargesId = charges[0]._id;
      await handleEdit(chargesId, 'goldPrice', updatedGoldPrice, 'charges');
  
      console.log('Documents and charges updated successfully');
      setDocuments(updatedDocuments);
      setIsLoading(false);
      setShowPopup(false);

      setRefresh(true);
    } catch (error) {
      console.error('Error updating documents and charges:', error);
    }
  };
  





  const handleApplySilverPrice = async () => {
    if (charges.length === 0) {
      console.error('Charges array is empty');
      return;
    }
  
    try {
      const updatedSilverPrice = silverPrice;
      setIsLoading(true);
      setShowPopup(true);
  
      // Update weemain documents
      const updatedDocuments = documents.map(document => {
        const updatedPrice = document.price + updatedSilverPrice;
        const updatedProfit = document.priceHundred - updatedPrice;
        return {
          ...document,
          price: updatedPrice,
          profit: updatedProfit
        };
      });
  
      await Promise.all(
        updatedDocuments.map(updatedDocument =>
          handleEdit(updatedDocument._id, 'price', updatedDocument.price, 'weemain')
        )
      );
  
      // Update charges document
      const chargesId = charges[0]._id;
      await handleEdit(chargesId, 'silverPrice', updatedSilverPrice, 'charges');
  
      console.log('Documents and charges updated successfully');
      setDocuments(updatedDocuments);
      setIsLoading(false);
      setShowPopup(false);

      setRefresh(true);
    } catch (error) {
      console.error('Error updating documents and charges:', error);
    }
  };
  



  const handleApplyPalladiumPrice = async () => {
    if (charges.length === 0) {
      console.error('Charges array is empty');
      return;
    }
  
    try {
      const updatedPalladiumPrice = palladiumPrice;
      setIsLoading(true);
      setShowPopup(true);
  
      // Update weemain documents
      const updatedDocuments = documents.map(document => {
        const updatedPrice = document.price + updatedPalladiumPrice;
        const updatedProfit = document.priceHundred - updatedPrice;
        return {
          ...document,
          price: updatedPrice,
          profit: updatedProfit
        };
      });
  
      await Promise.all(
        updatedDocuments.map(updatedDocument =>
          handleEdit(updatedDocument._id, 'price', updatedDocument.price, 'weemain')
        )
      );
  
      // Update charges document
      const chargesId = charges[0]._id;
      await handleEdit(chargesId, 'palladiumPrice', updatedPalladiumPrice, 'charges');
  
      console.log('Documents and charges updated successfully');
      setDocuments(updatedDocuments);
      setIsLoading(false);
      setShowPopup(false);

      setRefresh(true);
    } catch (error) {
      console.error('Error updating documents and charges:', error);
    }
  };
  
  const handleApplyCopperPrice = async () => {
    if (charges.length === 0) {
      console.error('Charges array is empty');
      return;
    }
  
    try {
      const updatedCopperPrice = copperPrice;
      setIsLoading(true);
      setShowPopup(true);
  
      // Update weemain documents
      const updatedDocuments = documents.map(document => {
        const updatedPrice = document.price + updatedCopperPrice;
        const updatedProfit = document.priceHundred - updatedPrice;
        return {
          ...document,
          price: updatedPrice,
          profit: updatedProfit
        };
      });
  
      await Promise.all(
        updatedDocuments.map(updatedDocument =>
          handleEdit(updatedDocument._id, 'price', updatedDocument.price, 'weemain')
        )
      );
  
      // Update charges document
      const chargesId = charges[0]._id;
      await handleEdit(chargesId, 'copperPrice', updatedCopperPrice, 'charges');
  
      console.log('Documents and charges updated successfully');
      setDocuments(updatedDocuments);
      setIsLoading(false);
      setShowPopup(false);

      setRefresh(true);
    } catch (error) {
      console.error('Error updating documents and charges:', error);
    }
  };
  
  
  

  return (
    <div className='adminmain'>
      {isLoading && showPopup && <Popup onClose={handlePopupClose} />}
      <ChargesInfo/>
      <div className="chargeinputs">
      <div className="metalswrap">
    <label>
      <input
        type="number"
        value={exportCharge}
        onChange={handleExportChargeChange}
      />
    </label>
    <button onClick={handleApplyExportCharge}>Apply New Export Charge</button>
    </div>

    <div className="metalswrap">
    <label>
      <input
        type="number"
        value={processingCharge}
        onChange={handleProcessingChargeChange}
      />
    </label>
    <button onClick={handleApplyProcessingCharge}>Apply New Processing Charge</button>
    </div>

    
    <div className="metalswrap">
    <label>
      <input
        type="number"
        value={discountCharge}
        onChange={handleDiscountChargeChange}
      />
    </label>
    <button onClick={handleApplyDiscountCharge}>Apply New Discount Charge</button>
    </div>

      <div>
      <div className="metalswrap">
        <label>
          <input
            type="number"
            value={exchangeRate}
            onChange={handleExchangeCharge}
          />
        </label>
        <button onClick={handleApplyExchangeRate}>Apply New Exchange Rate</button>
        </div>
      </div>
      </div>

      <div>
      <PreciousMetalsPrices/>
      <div className="chargeinputs">
      <div className="metalswrap">
         <div> Gold Price </div>
          <input 
            type="number"
            value={goldPrice}
            onChange={handleGoldPrice}
          />
        <button onClick={handleApplyGoldPrice}>Apply New Gold Price</button>
      </div>

      <div className="metalswrap">
      <div> Silver Price </div>
          <input
            type="number"
            value={silverPrice}
            onChange={handleSilverPrice}
          />
        <button onClick={handleApplySilverPrice}>Apply New Silver Price</button>
      </div>

      <div className="metalswrap">
      <div> Palladium Price </div>
          <input
            type="number"
            value={palladiumPrice}
            onChange={handlePalladiumPrice}
          />
        <button onClick={handleApplyPalladiumPrice}>Apply New Palladium Price</button>
      </div>

      <div className="metalswrap">
          Copper Price:
          <input
            type="number"
            value={copperPrice}
            onChange={handleCopperPrice}
          />
        <button onClick={handleApplyCopperPrice}>Apply New Copper Price</button>
      </div>
      </div>
      </div>
      <div className='pageheader'>Material Details</div>
      <table>
        <thead>
          <tr>
            <th>Wee Name</th>
            <th>Wee Description</th>
            <th>Comp One</th>
            <th>Comp Two</th>
            <th>Comp Three</th>
            <th>Comp Ave</th>
            <th>Price USD</th>
            <th>Profit</th>
            <th>Price EGP</th>
            <th>EGP 10%</th>
            <th>Copper</th>
            <th>Gold</th>
            <th>Silver</th>
            <th>Palladium</th>
            {/* Repeat the above pattern for other fields you want to display */}
          </tr>
        </thead>
        <tbody>
          {documents.map(document => (
            <tr key={document._id}>
              <td>
                <input
                  type="text"
                  value={updatedFields[document._id]?.weeName || document.weeName}
                  onChange={e => handleEdit(document._id, 'weeName', e.target.value)}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={updatedFields[document._id]?.weeDescription || document.weeDescription}
                  onChange={e => handleEdit(document._id, 'weeDescription', e.target.value)}
                />
              </td>
              <td>
                <input 
                  type="number"
                  value={updatedFields[document._id]?.compPriceOne ||  document.compPriceOne.toFixed(2)}
                  onChange={e => handleEdit(document._id, 'compPriceOne', Number(e.target.value))}
                />
              </td>
              <td>
                <input 
                  type="number"
                  value={updatedFields[document._id]?.compPriceTwo ||  document.compPriceTwo.toFixed(2)}
                  onChange={e => handleEdit(document._id, 'compPriceTwo', Number(e.target.value))}
                />
              </td>
              <td>
                <input 
                  type="number"
                  value={updatedFields[document._id]?.compPriceThree ||  document.compPriceThree.toFixed(2)}
                  onChange={e => handleEdit(document._id, 'compPriceThree', Number(e.target.value))}
                />
              </td>
              <td>
              <div className={`color ${updatedFields[document._id]?.compPriceAverage < document.egp ? 'green' : (updatedFields[document._id]?.compPriceAverage > document.egp ? 'red' : 'black')}`}>
              {Number(updatedFields[document._id]?.compPriceAverage).toFixed(2) || document.compPriceAverage.toFixed(2)}
            </div>
            </td>

              <td>
                <input 
                  type="number"
                  value={updatedFields[document._id]?.price ||  document.price.toFixed(2)}
                  onChange={e => handleEdit(document._id, 'price', Number(e.target.value))}
                />
              </td>
              <td>
                <input 
                  type="number"
                  value={updatedFields[document._id]?.profit ||  document.profit.toFixed(2)}
                  onChange={e => handleEdit(document._id, 'profit', Number(e.target.value))}
                />
              </td>
              <td>
                <input 
                  type="number"
                  value={updatedFields[document._id]?.egp ||  document.egp.toFixed(2)}
                  onChange={e => handleEdit(document._id, 'egp', Number(e.target.value))}
                />
              </td>
              <td>
                <input 
                  type="number"
                  value={updatedFields[document._id]?.priceTenPercentEGP ||  document.priceTenPercentEGP.toFixed(2)}
                  onChange={e => handleEdit(document._id, 'priceTenPercentEGP', Number(e.target.value))}
                />
              </td>
              <td>
                <input
                  type="number"
                  value={updatedFields[document._id]?.copper || document.copper}
                  onChange={e => handleEdit(document._id, 'copper', Number(e.target.value))}
                />
              </td>
              <td>
                <input
                  type="number"
                  value={updatedFields[document._id]?.gold || document.gold}
                  onChange={e => handleEdit(document._id, 'gold', Number(e.target.value))}
                />
              </td>
              <td>
                <input
                  type="number"
                  value={updatedFields[document._id]?.silver || document.silver}
                  onChange={e => handleEdit(document._id, 'silver', Number(e.target.value))}
                />
              </td>
              <td>
                <input
                  type="number"
                  value={updatedFields[document._id]?.palladium || document.palladium}
                  onChange={e => handleEdit(document._id, 'palladium', Number(e.target.value))}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button className='bottombutton' onClick={handleSaveChanges}>Save Changes</button>
      <button className='bottombutton' onClick={handleResetPrices}>Reset Prices</button>
    </div>
  );
}

export default AdminMain;

               

