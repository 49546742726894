import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../userAuth'; 
import { useNavigate, useLocation } from 'react-router-dom'; // Import useLocation
import './contactForm.css';

const ContactForm = ({ onContactAdded }) => {
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const location = useLocation(); // Use useLocation hook to access location object
  const userInfo = location.state?.userInfo || {};
  console.log('userInfoContactFormReceived', userInfo)
  const userId = location.state?.userInfo.id || {};
  const userName = location.state?.userInfo.name || {};
  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login');
    }
  }, [isLoggedIn, navigate]);
    const isLocal = window.location.hostname === 'localhost';
    const baseUrl = isLocal ? 'http://localhost:3001' : 'https://crg-1-pakistan-bf9fced80b73.herokuapp.com';
  const [newContact, setNewContact] = useState({
    name: '',
    number: '',
    address: '',
    lastContact: '',
    nextContact: '',
    typeOfContact: 'Call',
    materialType: 'Cats',
    notes: [],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewContact((prevContact) => ({ ...prevContact, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {

      const currentDate = new Date(); // Get the current date
      const oneMonthAgo = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, currentDate.getDate()); // Calculate one month ago
      

      const response = await axios.post(`${baseUrl}/api/contacts`, { ...newContact, userId, userName }); // Pass userId to the request
      onContactAdded(response.data);

      navigate('/contact-list');

      setNewContact({
        name: '',
        number: '',
        address: '',
        lastContact: '',
        nextContact: '',
        typeOfContact: 'Call',
        materialType: 'Cats',
        notes: [],
        nextPurchase: oneMonthAgo
      });
    } catch (error) {
      console.error('Error adding contact:', error);
    }
  };

  return (
    <div className='contactFormMain'>
                  {isLoggedIn && (
      <div className='contactFormSecond'>
      <div className='contactFormThird'>      
        <form onSubmit={handleSubmit}>
          <label className='label'>Name:</label>
          <input type="text" name="name" value={newContact.name} onChange={handleChange} required />

          <label>Number:</label>
          <input type="text" name="number" value={newContact.number} onChange={handleChange} />

          <label>Address:</label>
          <input type="text" name="address" value={newContact.address} onChange={handleChange} />

          <label className='label'>Last Contact:</label>
          <input type="date" name="lastContact" value={newContact.lastContact} onChange={handleChange} />

          <label className='label'>Next Contact:</label>
          <input type="date" name="nextContact" value={newContact.nextContact} onChange={handleChange} />

          <label className='label'>Type of Contact:</label>
          <select name="typeOfContact" value={newContact.typeOfContact} onChange={handleChange}>
            <option value="Call">Call</option>
            <option value="Visit">Visit</option>
            <option value="Delivery">Delivery</option>
          </select>
          <label className='label'>Material:</label>
          <select name="materialType" value={newContact.materialType} onChange={handleChange}>
            <option value="Weee">Weee</option>
            <option value="Cats">Cats</option>
            <option value="Both">Both</option>
          </select>
          <button className='bottombutton' type="submit">Add Customer</button>
        </form>
        </div>  
      </div>
        )}
    </div>
  );
};

export default ContactForm;
