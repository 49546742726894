import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faCog, faHome, faSignIn, faSignOut, faComputer, faDatabase, faCalculator, faUser } from '@fortawesome/free-solid-svg-icons';
import logo from './CRG.svg';
import './header.css';

function Header() {
  const navigate = useNavigate();
  // eslint-disable-next-line
  const [title, setTitle] = useState(null);

  const handleCartClick = () => {
    navigate('/cartaccess');
  };
  
  const handleSigninClick = () => {
    navigate('/login'); // Replace '/login' with the desired URL path for the login page
  };

  const handleSignoutClick = () => {
    // Perform logout actions if needed
    navigate('/');
    setTitle(null); // Clear the title value upon sign-out
    localStorage.removeItem('title'); // Remove the title value from localStorage
  };

  const renderAuthIcon = () => {
    if (localStorage.getItem('title')) {
      return (
        <div className="Settings-link">
          <FontAwesomeIcon onClick={handleSignoutClick} icon={faSignOut} className="Shopping-icon" />
        </div>
      );
    } else {
      // User is not logged in, show sign-in icon
      return (
        <div onClick={handleSigninClick} className="Settings-link">
          <FontAwesomeIcon icon={faSignIn} className="Shopping-icon" />
        </div>
      );
    }
  };

  useEffect(() => {
    const storedTitle = localStorage.getItem('title');
    if (storedTitle) {
      setTitle(storedTitle); 
    }
  }, []);

  return (
    <header className="App-header">
      <div className="App-header-center">
        <img src={logo} className="App-logo" alt="logo" />
      </div>
      <div className="App-header-right">
  
        {localStorage.getItem('title') === 'Admin'  && (
          <Link to="/customers" className="Settings-link">
            <FontAwesomeIcon icon={faDatabase} className="Shopping-icon" />
          </Link>
        )}

{(localStorage.getItem('title') === 'Sales' || localStorage.getItem('title') === 'Center') && (
            <Link to="/sales-login" className="Settings-link">
                <FontAwesomeIcon icon={faUser} className="Shopping-icon" />
            </Link>
        )}
  


        <Link to="/weee" className="Settings-link">
          <FontAwesomeIcon icon={faComputer} className="Shopping-icon" />
        </Link>

        <Link to="/" className="Settings-link">
          <FontAwesomeIcon icon={faHome} className="Shopping-icon" />
        </Link>
  
        {renderAuthIcon()}
      </div>
    </header>
  );  
}

export default Header;
